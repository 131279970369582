import React, { Component } from "react";
import { Provider } from "react-redux";
import "./App.css";
import "./styelSheets/antd.css";
import "./styelSheets/css/style.css";
import store from "./store";
import Routes from "./routes/index";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styelSheets/sass/style.css";

import {
	logEvent,
	resetLogs,
	setLocale,
	updateEventDuration,
} from "./modules/Common/store/actions";
import { authCheck, authLogin } from "./modules/Authintication/store/actions";
import CacheReset from "./CacheReset";
import { Spin } from "antd";
import LoadingIndicator from "./modules/Common/components/LoadingIndicator/LoadingIndicator";
import { loginAs, sendEvents } from "./modules/Common/service";
import { getStudentDetails } from "./modules/Dashboard/service";
import BaseComponent from "./modules/Common/components/BaseComponent/BaseComponent";

store.dispatch(authCheck());
store.dispatch(
	setLocale(
		localStorage.getItem("currentLocale") !== null
			? localStorage.getItem("currentLocale")
			: "english"
	)
);
sessionStorage.setItem("currentPage", 1);

class App extends BaseComponent {
	state = {
		loading: true,
	};

	componentDidMount() {
		if ("scrollRestoration" in window.history) {
			window.history.scrollRestoration = "manual";
		}
		if (this.parseQuerstring(window.location.search.substr(1)).id) {
			sessionStorage.setItem(
				"access_token",
				this.parseQuerstring(window.location.search.substr(1)).token
			);
			this.loginAsStudent(
				window.location.search.substr(1).type,
				window.location.search.substr(1).targetID
			);
		} else {
			this.setState({
				loading: false,
			});
		}
		this.navigateToType();
		setInterval(() => {
			let events;
			try {
				events = JSON.parse(
					window.localStorage.getItem("events")
						? window.localStorage.getItem("events")
						: {}
				);
			} catch (error) {
				events = [];
			}
			let body = {
				events: events
					? events
							.filter((ev) => !!ev.data.id)
							.map((evt, i) => ({
								eventTypeId: evt.data.id,
								eventData: {
									targetID: evt.data.targetID,
									parentTargetID: evt.data.parentID,
									title: evt.data.title,
									durationInMins: (evt.data.duration / 60).toFixed(2),
								},
							}))
					: [],
			};
			sendEvents(
				body,
				() => {
					store.dispatch(resetLogs());
					// let location = window.location.pathname.replace('/student/', '')
					try {
						// clearInterval(this.durationCounter)
						// store.dispatch(logEvent({
						//     type: 'PAGE_NAVIGATE',
						//     data: {
						//         type: location === 'assignmentDetails' ? 'ASSGINMENT_DETAILS' :
						//         location === 'courseDetails' ? 'COURSE_DETAILS' :
						//         location === 'onlinesessionDetails' ? 'ONLINESESSION_DETAILS' :
						//         location.toUpperCase(),
						//         id: this.getEventTypeID(
						//           location === 'assignmentDetails' ? 'ASSGINMENT_DETAILS' :
						//           location === 'courseDetails' ? 'COURSE_DETAILS' :
						//           location === 'onlinesessionDetails' ? 'ONLINESESSION_DETAILS' :
						//           location.toUpperCase()
						//         ),
						//         targetID: null,
						//         parentID: location === 'assignmentDetails' ? this.getEventTypeID('ASSIGNMENTS') :
						//         location === 'courseDetails' ? this.getEventTypeID('COURSES') :
						//         location === 'onlinesessionDetails' ? this.getEventTypeID('ONLINESESSIONS') :
						//         null,
						//         duration: 0,
						//         title: null,
						//     }
						// }))
						// this.durationCounter = setInterval(() => {
						//     store.dispatch(updateEventDuration(this.getEventTypeID(
						//       location === 'assignmentDetails' ? 'ASSGINMENT_DETAILS' :
						//       location === 'courseDetails' ? 'COURSE_DETAILS' :
						//       location === 'onlinesessionDetails' ? 'ONLINESESSION_DETAILS' :
						//       location.toUpperCase()
						//     )))
						// }, 1000)
					} catch (error) {
						console.log(error);
					}
				},
				(error) => {
					console.log(error);
				}
			);
		}, 120000);
	}

	parseQuerstring(search) {
		var obj = {};
		search.split("&").forEach((a) => {
			let [key, val] = a.split("=");
			obj = { ...obj, [key]: val };
		});
		return obj;
	}

	loginAsStudent = (type, targetID) => {
		let body = {
			username: this.parseQuerstring(window.location.search.substr(1)).id,
			password: null,
			clientId: "FoundationAPI",
			clientSecret: "FoundationSecretKey",
		};
		loginAs(
			body,
			(res) => {
				store.dispatch(
					authLogin({
						token: res.data.accessToken,
						roles:
							res.data.roles.length > 0
								? res.data.roles
								: [
										"Home",
										"Courses",
										"Assignments",
										"Exams",
										"Settings",
										"Calendar",
										"AssignmentDetails",
										"OnlineSessions",
										"OnlineSessiondetails",
										"CourseDetails",
								  ],
						refreshToken: res.data.refreshToken,
						token_expiry: res.data.tokenExpiration,
						rememberMe: false,
						user: { name: res.data.name, image: res.data.image },
						userType: res.data.userType,
					})
				);
				getStudentDetails(
					(res) => {
						try {
							let user = {
								name: res.data.fullName,
								image: res.data.image,
								className: `${res.data.educationalGradeName} ${res.data.className}`,
								isBanned: res.data.isBanned,
							};
							window.sessionStorage.setItem("user", JSON.stringify(user));
						} catch (error) {
							return false;
						}
						this.setState({
							loading: false,
						});
						window.location.href.replace("/student/home");
					},
					(errors) => {
						this.setState({ loading: false });
					}
				);
				if (targetID) {
					this.navigateToType(type, targetID);
				}
			},
			(errors) => {
				this.setState({
					loading: false,
				});
			}
		);
	};

	navigateToType = (type, targetID) => {
		switch (type) {
			case "quiz":
				this.props.history.push(`/quiz/${targetID}`);
				break;
			default:
				break;
		}
	};

	render() {
		return this.state.loading ? (
			<LoadingIndicator />
		) : (
			<CacheReset>
				{({ loading, isLatestVersion, refreshCacheAndReload }) => {
					if (loading) return <LoadingIndicator />;
					if (!loading && !isLatestVersion) {
						refreshCacheAndReload();
					}
					return (
						<Provider store={store}>
							<Routes />
						</Provider>
					);
				}}
			</CacheReset>
		);
	}
}

export default App;
