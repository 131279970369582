/**
 * Import action types here
 */
import { 
    SET_DRAWER_ACTIVE_TAB, SET_COURSES_FILTRATION, SET_COURSE_DETAILS, SET_FILTRATION_RESULT_VISIBILITY, SET_FILTRATION_BAR_VISIBILITY,
    SET_FILTRATION_ASSIGNMENT_DATE,
} from "./action-types";

/**
 * Module internal initial state
 */
const initialState = {
    drawerActiveTab: '1',
    date: '',
    courseName: '',
    subject: '',
    courseDetails: null,
    isFiltrationResultVisible: false,
    isFiltrationBarVisible: false,
    filterdAssigmentDate: null,
};  


/**
 * Checks dispatched actions and updates state accordingly
 * @param {Object} state 
 * @param {Object} action 
 * @returns {Function} 
 */

const reducer = (state = initialState, {type, payload = null}) => {
    switch (type) {
        case SET_COURSES_FILTRATION: 
            return setCoursesFiltration(state, payload)
        case SET_DRAWER_ACTIVE_TAB: 
            return setDrawerActiveTab(state, payload)
        case SET_COURSE_DETAILS:
            return setCourseDetails(state, payload)
        case SET_FILTRATION_RESULT_VISIBILITY:
            return setFiltrationResultsVisibility(state, payload)
        case SET_FILTRATION_BAR_VISIBILITY:
            return setFiltrationBarVisibility(state, payload)
        case SET_FILTRATION_ASSIGNMENT_DATE:
            return setFilterdAssigmentDate(state, payload)
        default:
            return state;
    }
} 

/**
 * Returns an updated version of the state based on the action
 * @param {Object} state 
 * @param {Object} payload 
 * @return {Object} state
 */

function setDrawerActiveTab(state, payload){
    return {
        ...state, drawerActiveTab: payload
    }
}

function setCoursesFiltration(state, payload) {
    let subject, date, courseName
    if(payload.type === 'subject'){
        subject = payload.value
    }
    else if(payload.type === 'date'){
        date = payload.value
    }
    else {
        courseName = payload.value
    }
    return {
        ...state, subject, date, courseName
    }
}

function setCourseDetails(state, payload) {
    return {
        ...state, courseDetails: payload
    }
}

function setFiltrationResultsVisibility(state, payload) {
    return {
        ...state, isFiltrationResultVisible: payload
    }
}

function setFiltrationBarVisibility(state, payload) {
    return {
        ...state, isFiltrationBarVisible: payload
    }
}

function setFilterdAssigmentDate(state, payload) {
    return {
        ...state, filterdAssigmentDate: payload
    }
}

export default reducer