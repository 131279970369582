import React from 'react'
import { notification } from 'antd';
import store from '../../../../store'

class BaseComponent extends React.Component {

	/**
	 * 
	 * @param {string} type 'error' | 'success' 
	 * @param {[]} messages 
	 */
    openNotification = (type, messages) => {
		let locale = localStorage.getItem('currentLocale')
		let headline = locale === 'english' ? (type === "error" ? "Error occured" : "Successful operation") : 
		(type === "error" ? "هناك خطأ" : "عملية ناجحة") 
		if(messages){
			messages.forEach(message => {
				notification[type]({
					message: headline,
					description: message,
					placement: locale === 'english' ? 'topRight' : 'topLeft'
				});
			});
		}
		else {
			notification.error({
				message: headline,
				description: locale === 'english' ? 'Something went wrong' : 'حدث خطأ ما',
				placement: locale === 'english' ? 'topRight' : 'topLeft'
			})
		}
	};

	getCurrentLocation(){
		if (navigator && navigator.geolocation) {
            return new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(pos => {
                    const coords = pos.coords;
                    resolve({
                        lat: coords.latitude,
                        lng: coords.longitude
					});
                }, () => {
					fetch('http://ip-api.com/json').then(res => res.json()).then(data => {
						resolve({
							lat: data.lat,
							lng: data.lon
						})
					}).catch(() => {
						resolve({
							lat: null,
							lng: null
						})
					})
				});
            });
        }
        return {lat: 0,lng: 0};
	}

	fileDownload(data, filename, mime, bom) {
		var blobData = (typeof bom !== 'undefined') ? [bom, data] : [data]
		var blob = new Blob(blobData, {type: mime || 'application/octet-stream'});
		if (typeof window.navigator.msSaveBlob !== 'undefined') {
			// IE workaround for "HTML7007: One or more blob URLs were 
			// revoked by closing the blob for which they were created. 
			// These URLs will no longer resolve as the data backing 
			// the URL has been freed."
			window.navigator.msSaveBlob(blob, filename);
		}
		else {
			var blobURL = window.URL.createObjectURL(blob);
			var tempLink = document.createElement('a');
			tempLink.style.display = 'none';
			tempLink.href = blobURL;
			tempLink.setAttribute('download', filename); 
			
			// Safari thinks _blank anchor are pop ups. We only want to set _blank
			// target if the browser does not support the HTML5 download attribute.
			// This allows you to download files in desktop safari if pop up blocking 
			// is enabled.
			if (typeof tempLink.download === 'undefined') {
				tempLink.setAttribute('target', '_blank');
			}
			
			document.body.appendChild(tempLink);
			tempLink.click();
			
			// Fixes "webkit blob resource error 1"
			setTimeout(function() {
				document.body.removeChild(tempLink);
				window.URL.revokeObjectURL(blobURL);
			}, 0)
		}
	}

	isNumberKey = evt => {
		var char = String.fromCharCode(evt.which);
		let allowedKeys = [97, 98, 99, 100, 101, 102, 103, 104, 105, 8, 46, 37, 39, 96, 110];
		if(parseFloat(evt.target.value) > 999.9 && evt.which !== 8){
			evt.preventDefault()
		}
		if (/[0-9]/.test(char) || allowedKeys.includes(evt.keyCode)) {
		  return true;
		} else {
		  evt.preventDefault();
		}
	}
	
	requestNotificationsPermission(onGrant, onDeny) {
		console.log('Requesting permission...');
		Notification.requestPermission().then((permission) => {
		if (permission === 'granted') {
			onGrant()
		} else {
			onDeny()	
		}
		});
	}

	retry(fn) {
		return new Promise((resolve, reject) => {
		  fn()
			.then(resolve)
			.catch((error) => {
				console.log(error)
				if(error.code === 'MODULE_NOT_FOUND'){
					window.location.reload(true)
				}
			});
		});
	}

	getFileTypeIcon(ext){
		let icons = []
		let types = [
			{
				icon: 'doc',
				extensions: ['pdf', 'docx', 'doc']
			},
			{
				icon: 'video',
				extensions: ['avi', 'mp4', 'mov', 'webm']
			},
			{
				icon: 'photo',
				extensions: ['jpg', 'jpeg', 'png']
			},
			{
				icon: 'mic',
				extensions: ['mp3']
			}
		]
		types.forEach(type => {
			if(type.extensions.includes(ext)){
				icons.push(type.icon)
			}
		})
		return icons
	}

	sortByKey(array, key) {
		return array.sort(function(a, b) {
			var x = a[key]; var y = b[key];
			return ((x < y) ? -1 : ((x > y) ? 1 : 0));
		});
	}

	getWeekDay = (index) => {
		let locale = localStorage.getItem('currentLocale')
		let weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
		let weekdaysAr = ['الأحد', 'الأثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت']
		return locale === 'english' ? weekdays[index] : weekdaysAr[index]
	}

	getDayTime = (index) => {
		let locale = localStorage.getItem('currentLocale')
		let weekdays = ['08:00', '09:00', '10:00', '11:00', '12:00', '01:00', '02:00','03:00']
		let weekdaysAr = ['۰۰:۰۸','۰۰:۰۹','۰۰:۱۰','۰۰:۱۱','۰۰:۱۲','۰۰:۰۱','۰۰:۰۲','۰۰:۰۳']
		return locale === 'english' ? weekdays[index] : weekdaysAr[index]
	}

	getEventTypeID = (type) => {
		const typesIDs = {
			ASSIGNMENT_MEDIA : '98d52441-a5c0-444e-90e5-050f623c2545',
			EXAMS: '96ca1214-ef22-4280-ad11-09921744cca2',	
			ASSGINMENT_DETAILS: 'f331ceda-de93-4207-b253-1bff82b676bc',
			COURSE_MEDIA: 'e35d7985-e3fe-4530-be8b-1d4de42a335a',
			HOME: '32ddfa83-be59-4bb5-a65e-2acd6b999594',
			COURSES: '6876e647-3272-4837-b94a-3cc37cb36721',
			ONLINESESSIONS: '5efdeac8-ac90-44bc-9d01-44d1c07e9948',
			ONLINESESSION_DETAILS: '21564d5b-32ee-436d-9ebe-72e053fcb8af',
			ASSIGNMENTS: '7442ec66-db4d-4ec1-921f-cf841b4456ce',
			ONLINESESSION_MEDIA: 'c16c3b43-1b71-43b3-8fec-dcee4a7594c6',
			COURSE_DETAILS: '5fa7693d-5d70-4018-9ccd-e0788e5c569b',
			CALENDAR: 'ceb8bcb0-5ac8-46f5-a79b-f342b6f0b7e0',
			EXAM_DETAILS: '1fb8767f-aabc-4749-b5b2-f95543ffc2bc'
		}
		for (const key in typesIDs) {
			if(key === type) {
				return typesIDs[key]
			}
		}
	}

}

export default BaseComponent