export const pa = {
  auth: {
    login: "بوج ان",
    logout: "Logout",
    rememberMe: "Remember me",
    signIn: "Sign in",
    email: "Email",
    mail_massage: "The input is not valid e-mail!",
    mail_req_massage: "Please input your e-mail!",
    password_placeholder: "Enter your password",
    plaeaseEnterMail:
      "Please Enter your email then click the button below to request another one.",
    password: "Password",
    forgetPassword: "Forget Password?",
    Forget_Password: "Forget Password",
    sammary:
      "Please enter your email so that we can  send you a rest password email.",
    passwordMassage: "Please input your password!",
    backToSignin: "Back to sign in",
    sendMail: "Send email",
    sorry505:
      "The server encountered an internal error or misconfiguration and  was  unable to complete your request",
    resetSent: "Reset code was sent successfully",
    confirmPassword: "Confirm new password",
    restPass_placeholder: "Confirm your password",
    confirm_massage: "Please confirm your password!",
    Reset: "Reset",
    cancle: "Cancel",
    CheckInpoxp1: "Please check your inbox or spam folder at",
    CheckInpoxp2: "For a link to reset your password .",
    GoToMail: "Go to your mail",
    Ok: "OK",
    oops: "Oops!! This URL Is Not Valid Anymore.",
    ResendMail: "Resend mail",
    passwordmin: "Password must be min 6 characters",
    passwordmax: "Password must be max 20 characters",
    passwordexpresion:
      "Password must be at least 8 characters including capital and small letters.",
    profile: "Profile",
    personalInfo: "Personal information",
    name: "Name",
    nameMassage: "Please input your name!",
    namePlaceholder: "Enter your name",
    Security: "Security",
    Account: "Account",
    Change: "Change",
    Deactivate: "Deactivate",
    DeactivateAccount: "Deactivate account",
    SaveChanges: "Save changes",
    ChangePassword: "Change password",
    NoCancel: "No, Cancel",
    YesDeactivate: "Yes, Deactivate",
    PasswordsChanged: "Passwords changed successfully",
    AccountDeactivated: "Account Deactivated!",
    YourAccountDeactivated:
      "Your account have be deactivated, please be informed that you’ll never be able to login again or restore your account",
    Delete: "Delete",
    JoinYourClassNow: "Join Your Class Now!",
    Studentcode: "Student code",
    Studentcoderequired: "please input Student code",
    AreYouSoure: "Are you sure you want to delete this data ?",
    YesSave: "Yes, Save",
    NoSave: "No, Don’t Save",
    NewPassword: "New password",
    Cancel: "Cancel",
    EnterPassFor: "Please enter Your Password for",
    TimedOut:
      "You are being timed-out due to inactivity .please choose to stay ",
    SinginOrLogout: "Signed-in or log-out .",
    otherwise: "Otherwise , you will be logged out automatically",
    minute: "after 1 minute.",
    stay: "Stay logged-in",
    inactivityMessage: "You have been logged-out due to inactivity",
    incorrectOldPassword: "Old password is incorrect",
    Imagemustsmallerthan5MB: "Image must smaller than 5MB!",
    YoucanonlyuploadJPGPNG: "You can only upload JPG/PNG file!",
    createAccount: "Create account",
    aleadyHaveAccount: "Aleady have an account ?",
    signUp: "Sign up"
  },
  notifi: {
    MarkAll: "Mark all reeded",
    NotifiContent: "Welcome back to Starwallet labs admin panel",
    fiveMin: "5 Mins ago",
    Delete: "Delete"
  },
  validationMessages: {
		shortDescriptionLength: "Short description must be less than 70 character",
		descriptionLength: "Description must be less than 500 characters",
		titleLength: "Title must be less than 30 character",
		titleLengthMax: "Title must be less than 60 character",
		titleLengthMin: 'Title must be at least 3 characters',
		firstNameLength: "First name must be less than 20 character",
		firstNameSpaces: "First name can't contain more than one space",
		imageSize: 'Image size must be less than 10 MB',
        videoSize: 'Video size must be less than 200 MB',
        invalidFormat: 'Please select a valid image or video type',
        invalidWidth: 'Image width must be more than 400 pixel',
        invalidHeight: 'Image height must be more than 400 pixel',
        limitExceeded: "You can't select more than 20 files",
        invalidImages: 'images are invalid',
		AreYouSureDelete: 'Are you sure you want to delete ',
        deleteFile: 'Delete File',
        fileSizeExceed: "File size can't exceed ",
        fileNotSupported: ' files are not supported',
	},
  home: {
    exportExcel: "Export Excel"
  },
  header: {
    Search: "Search...",
    Editprofile: "Edit profile",
    SUPERADMIN: "SUPER admin",
    NOTIFICATIONS: "Notifications",
    Seeall: "See all"
  },
  errors: {
    Unauthorized: "Unauthorized",
    sorry401: "Sorry , your request could not be processed.",
    goToHome: "Go To Home page",
    sorry403:
      "We are sorry , but you do not have access to this page or resource.",
    Access: "Access Denied",
    notFound: "Page not found",
    error_text:
      " The page you’re looking for might have been removed, had its name changed or is temporarily unavailable.",
    serverError: "Internal server error"
  },
  MScreens: {
    LinkExpired: "Link expired",
    plaeaseEnterMail:
      "Please enter your email then click the button below to request another one.",
    email: "Email",
    mail_massage: "The input is not valid e-mail!",
    mail_req_massage: "Please input your e-mail!",
    ResendMail: "Resend mail",
    oops: "Oops!! This url Is Not Valid Anymore.",
    Ok: "OK",
    PasswordReset: "Passwords reset successfully",
    ResetPassword: "Rest new password",
    NewPassword: "New password",
    passwordMassage: "Please input your password!",
    passwordmin: "Password must be min 8 characters",
    passwordmax: "Password must be max 20 characters",
    passwordexpresion:
      "Password must be at least 8 characters including capital and small letters.",
    password_placeholder: "Enter your password",
    confirmPassword: "Confirm new password",
    confirm_massage: "Please confirm your password!",
    restPass_placeholder: "Confirm your password",
    cancle: "Cancel",
    resetSent: "Reset code was sent successfully",
    Reset: "Reset",
    CheckInpoxp1: "Please check your inbox or spam folder at",
    CheckInpoxp2: "for a link to reset your password .",
    backToSignin: "Back to sign in"
  },
  settings: {
    PasswordsChanged: "Passwords changed successfully",
    successfullyDeleted: "successfully deleted",
    ChangePassword: "change password",
    NewPassword: "New password",
    passwordMassage: "Please input your password!",
    passwordmin: "Password must be min 8 characters",
    passwordmax: "Password must be max 20 characters",
    passwordexpresion:
      "Password expresion that requires one lower case letter, one upper case letter, one digit",
    password_placeholder: "Enter your password",
    confirmPassword: "Confirm new password",
    confirm_massage: "Please confirm your password!",
    restPass_placeholder: "Confirm your password",
    logout: "logout",
    AccountDeactivated: "Account deactivated!",
    YourAccountDeactivated:
      "Your account have be deactivated, please be informed that you’ll never be able to login again or restore your account",
    YesSave: "Yes, Save",
    NoSave: "No, Don’t Save",
    Cancel: "Cancel",
    Delete: "Delete",
    SaveChanges: "Save changes",
    AreYouSoure: "Do you want to save this data ?",
    signIn: "Sign in",
    EnterPassFor: "Please enter your password for",
    password: "Password",
    passwordMassage: "Please input your password!",
    passwordmin: "Password must be min 8 characters",
    passwordmax: "Password must be max 20 characters",
    passwordexpresion:
      "Password expresion that requires one lower case letter, one upper case letter, one digit",
    password_placeholder: "Enter your password"
  },
  setting: {
    Settings: "Settings",
    Integratedappsmasterata: "Integrated apps master data",
    GoogleRECAPTCHA: "Google recaptcha",
    GoogleAnalyticsTrackingCode: "Google analytics tracking code",
    FacebookAppID: "Facebook app iD",
    FacebookAppSecret: "Facebook app secret",
    LinkedInAppID: "LinkedIn app iD",
    EmailedLinksSettings: "Emailed links settings",
    Emailverification: "Email verification",
    Enable: "Enable",
    Emailverificationlinkexpirytimeminutes:
      "Email verification link expiry time (minutes)",
    Mins: "Mins",
    EmailNotifications: "Email notifications",
    Resetpasslinkverificationlinkexpirytimeminutes:
      "Reset pass link verification link expiry time (minutes)",
    Resendmailinterval: "Resend mail interval",
    Tries: "Tries",
    Xplacholder: "xxxx-xxxx-xxxx"
  },
  Privileges: {
    AddRole: "Add Role",
    Newrole: "New Role",
    Personalinfo: "Personal info",
    Rolename: "Role name",
    ISACTIVE: "IS active",
    Hidetranslations: "Hide translations",
    Showtranslations: "Show translations",
    TranslateIntoAr: "Translate into “عربي“",
    TranslateIntoFr: "Translate into “française“",
    Enable: "Enable",
    Rolestatus: "Role status",
    Privileges: "Privileges",
    Privilege: "Privilege",
    AddPrivileges: "Add Privileges",
    SelectPrivileges: "Select Privileges",
    PleaseselectPrivilegestoAssign: "Please select Privileges to assign",
    AssignedPrivileges: "Assigned Privileges",
    Admins: "Admins",
    AddAdmins: "Add admins",
    SelectAdmins: "Select admins",
    Admin: "Admin",
    AddAdmin: "Add admin",
    SelectAdmin: "Select admin",
    PleaseselectAdminstoAssign: "Please select admins to assign",
    deletedSuccessfully: "Selected roles deleted successfully",
    editedSuccessfully: "Selected role edited successfully",
    addedSuccessfully: "Role added successfully"
  },
  currency: {
    Currencyname: "Currency name",
    Addcurrency: "Add currency",
    Newcurrency: "New currency",
    currencyinfo: "Currency info"
  },
  users: {
    USERS: "USERS",
    Name: "Name",
    Age: "Age",
    Email: "Email",
    Address: "Address",
    Admins: "Admins",
    UpdateAdmins: "Update Admins",
    Adduser: "Add admin",
    Personalinfo: "Personal info",
    Rules: "Roles",
    Addrule: "Add role",
    Selectrule: "Select role",
    PleaseselectruletoAssign: "Please select role to Assign",
    Assignedrules: "Assigned roles",
    deletedSuccessfully: "Selected users deleted successfully",
    editedSuccessfully: "Selected user edited successfully",
    addedSuccessfully: "User added successfully"
  },
  grid: {
    New: "New ",
    goTo: "Go to",
    gridSearch: "Search",
    reset: "Reset",
    sureToCancel: "Sure to cancel?",
    no: "No"
  },
  buttons: {
    Close: "Close",
    SaveNew: "Save & new",
    Save: "Save",
    Cancel: "Cancel",
    UpdateNew: "Update & new",
    Update: "Update",
    Duplicate: "Duplicate",
    Delete: "Delete",
    View: "View",
    Archive: "Archive",
    Edit: "Edit",
    confirm: "Are you sure you want to ",
    selectToEdit: "Please select one row to edit",
    selectToDelete: "Please select one row or more to delete",
    change: "Change",
    remove: "Remove",
    Send: "Send",
    done: 'Done',
    clear: 'Clear',
    qu: "?",
    selectAll: "Select all",
    deleteTheRest: "Delete the rest",
    add: "Add",
    YesCancel: "Yes, cancel",
    NoDontCancel: "No, Don’t cancel",
    rotate: "Rotate",
    crop: "Crop",
    open: 'Open',
    Answer:'Answer'
  },
  inputs: {
    inputrequired: "Input required",
    PleaseinputyourFirstname: "Please input your first name!",
    PleaseinputyourMiddlename: "Please input your middle name!",
    PleaseinputyourLastName: "Please input your last name!",
    Pleaseinputyourphonenumber: "Please input your phone number!",
    Firstname: "First name",
    Middlename: "Middle name",
    LastName: "Last name",
    PhoneNumber: "Phone number",
    phonemin: "Phone can't be less than 8 numbers",
    phonemax: "Phone can't be more than 11 numbers",
    phone: "phone",
    Email: "E-mail",
    TheinputisnotvalidEmail: "The input is not valid e-mail!",
    PleaseinputyourEmail: "Please input your e-mail!",
    EmailAlreadyExists: "Email already exists",
    Firstnamemustcharacters: "Name must be min 3 characters",
    namecantacceptspace: "Name dosen't accept space",
    Namecanacceptspecialcharacters: "Name can't accept  special characters",
    Namecanacceptnumbers: "Name can't accept numbers",
    passwordDoesnotMatch: "Password and confirmation does not match"
  },
  masterData: {
    basicInfo: "Basic info",
    label: "Master data",
    add: "Add",
    name: "Name",
    email: "Email",
    value: "Value",
    isActive: "Is active",
    active: "Active",
    inActive: "Inactive",
    translate: "Translation",
    hideTranslations: "Hide translations",
    nameMaxLength: "Name length must not exceed 60 character",
    nameTranslationMaxLength:
      "Name translation max length must not exceed 60 character",
    nameMinLength: "Name minimum length is 3 characters",
    nameTranslationMinLength: "Name translation minimum length is 3 characters",
    namePlaceholder: "Enter name here",
    valuePlaceholder: "Enter value ex:'25'",
    nameTranslationPlaceholder: "Enter name translation here",
    deletedSuccessfully: "Selected data deleted successfully",
    editedSuccessfully: "Selected data edited successfully",
    addedSuccessfully: "Data added successfully",
    theFollowingData: "The following data ",
    inUseData: " are in use and can't be deleted",
    inUseActivated: " is in use and can't be deactivated"
  },
  countries: {
    basicInfo: "Basic info",
    label: "Country",
    deletedSuccessfully: "Selected countries deleted successfully",
    editedSuccessfully: "Selected country edited successfully",
    addedSuccessfully: "Country added successfully",
    nullFlag: "Please select an image",
    countryCurrency: "Country's currency",
    currencyPlaceholder: "Select country's currency",
    countryCodePlaceholder: "Enter country phone code, ex: +20",
    countryCode: "Country Code"
  },
  governorates: {
    basicInfo: "Basic info",
    label: "Governorate",
    deletedSuccessfully: "Selected governorates deleted successfully",
    editedSuccessfully: "Selected governorate edited successfully",
    addedSuccessfully: "Governorate added successfully"
  },
  schools: {
    SchoolName: "School name",
    Country: "Country",
    AddSchool: "Add school",
    State: "State",
    selectState: "Select a state",
    Fax: "Fax",
    AddressLine1: "Address line 1",
    AddressLine2: "Address line 2",
    PaymentMethod: "Payment method",
    Website: "Website",
    SchoolEmail: "School e-mail",
    AllowedStudents: "Allowed students",
    ContactPersonName: "Contact Person name",
    pleaseEngerName: "Please enter Your name",
    Position: "Position",
    enterPosition: "Please enter your position",
    email: "Email",
    enterEmail: "Please enter your email",
    Country: "Country",
    selectCountery: "Select a country",
    addContactPersonData: "Add contact",
    invalidURL: "Please enter a valid url",
    schoolData: "School data",
    contactPersonData: "Contact person Data",
    contactWorkPhone: "Work Phone",
    contactPersonalPhone: "Personal Phone",
    addContactModalTitle: "Add contact person"
  },
  sideBar: {
    Home: 'Home',
    Courses: 'Courses',
    Assignments: 'Assignments',
    Exams: 'Exams',
    Results: 'Results',
    Schedule: 'Schedule',
    Calendar: 'Calendar',
    Timetable: 'Timetable',
    OnlineSessions: 'Online Sessions'
  },
  payment: {
    PaymentTypes: "Payment types",
    PaymentHistory: "Payment history",
    Chooseyourpaymentplan: "Choose your payment plan that you will follow:",
    SelectPaymenttype: "Select Payment type",
    Features: "Features:",
    CurrentStudentsNumber: "Current students number:",
    student: "Student",
    Changesby: "Changes by :",
    EffectiveDate: "Effective date",
    LastChanges: "Last changes :",
    seemore: "See more",
    CurrentTotalCost: "Current total cost :",
    perStu: "L.E per 1 Stu.",
    NumberofdaysuntileveryPayment: "Number of days until every payment.",
    InspecificDayeverymonths: "In specific day every N. of months.",
    days: "Days",
    Mon: "Mon."
  },
  Stations: {
    Newstation: "New station",
    ViewStation: "View station",
    StationsName: "Stations name",
    NofChargers: "N. of chargers",
    ChoutofService: "Ch. out of service",
    Governorate: "Governorate",
    Location: "Location",
    Chargers: "Chargers",
    Available: "Available",
    Address: "Address",
    Directions: "Directions",
    DirectionsToStation: "Directions to station",
    StationsNearYou: "Charging stations near you",
    Findtheneareststation: " Find the nearest station to your current location",
    all: "All",
    AddNewStation: "Add new station",
    StationName: "Station name",
    StationNameTranslation: "Station name translation",
    PleaseInputStationName: "Please enter station name ",
    stationNamePlaceHolder: "Write the name of the station",
    stationNameTranslationPlaceHolder: "Write station name translation",
    NumberOfChargers: "Number of chargers",
    PleaseInputNumberOfChargers: "Please input number of chargers",
    NumberOfChargersPlaceholder: "set the number of available chargers",
    NumberOfOutOfServiceChargers: "Number of out Of service chargers",
    PleaseinputNumberOfoutOfService:
      "Please input number of out of service chargers",
    outOfServiceChargersPlaceholder:
      "Set the number of out of service chargers",
    Governorate: "Governorate",
    PleaseInputGovernorate: "Please input governorate",
    stationGovernorate: "Station governorate",
    LocationOnMap: "Location on map",
    PleaseInputLocationonMap: "Please input location on map",
    LocationPlaceholder: "Pick the station on the map to set the location",
    chargerIdPlaceholder: "Insert charger id",
    commentPlaceholder: "Insert a comment",
    addCharger: "+ Add charger",
    chargerID: "Charger iD",
    chargerStatus: "Status",
    chargerComment: "Comment",
    Tiles: "Tiles",
    Map: " Map",
    MoreInfo: "More info",
    stationAddedSuccessfully: "Station added successfully",
    stationEditSuccessfully: "Station edited successfully",
    chargersNo: "Chargers num",
    outOfServiceChargersNo: "Out of service",
    governateName: "Governorate name",
    alreadyExists: "Charger already exists"
  },
  Customers: {
    CustomersName: "Customers name",
    Email: "Email",
    Phone: "Phone",
    CardStatus: "Card status",
    Lastcharge: " Last charge",
    ViewProfile: "View profile",
    ViewCard: "View card",
    MobilePhone: "Mobile phone",
    Age: "Age",
    Gender: "Gender",
    CarType: "Car type",
    BatteryRange: "Battery range",
    carlicense: "car license",
    PersonalInfo: "Personal info",
    CarInfo: "Car info",
    Comments: "Comments",
    writeyourcommenthere: "write your comment here",
    active: "Active",
    inactive: "Inactive",
    inProgress: "In Progress",
    delivered: "Delivered",
    Requested: "Requested",
    needsAction: "Needs action",
    inDelivery: "In delivery",
    canceled: "Canceled",
    RequestInfo: "Request info",
    new: "New",
    address: "Address",
    deliveryAddress: "Delivery address",
    male: "Male",
    fmale: "Female",
    customerBan: "Customer ban",
    commentSent: "Comment succefully sent",
    statusUpdated: "Status succefully updated ",
    userBaned: "User succefully banned",
    userActivated: "User succefully activated"
  },
  PricingSettings: {
    Settings: "Settings",
    MinimumCharge: "Minimum charge",
    KW: "KW",
    CurrentAC: "Alternating current (AC)",
    CurrentDC: "Direct current (DC)",
    ChargersPricingUpdated: "Chargers pricing successfully updated"
  },
  infinty: {
    EVChargingpoints: "Welcome to the fastest growing electric ",
    foryourbusiness: "vehicle charging network in Egypt",
    Morethan17Stations: "More than 17 EV-Charging stations in greater cairo",
    Locateyourneareststation: "Locate your nearest station",
    EnvironmentFriendlyDrivingPleasure:
      "Environment-Friendly, Driving Pleasure",
    zero: "Zero",
    emissionisourvision: "Emission is our vision",
    Getyour: "Get your",
    ChargingCard: "Charging Card",
    ChargingCardArticle:
      "To Be able to charge at any infinity-e charging station, Order Your Charging card now and Enjoy Free Charging for a Limited time Only.",
    GetYourCard: "Get your card",
    Stations: "Stations",
    EVchargingstationstodate: "EV-charging stations to date",
    charginglocations: "charging locations",
    charginglocationsby2021: "charging locations by 2021",
    Neareststation: "Stations near you",
    Setlocation: "Set location",
    typeyourlocation: "type your location here…",
    LocatetheNearestStation: "Locate the nearest infinity-e charging station",
    Findtheneareststation: "Find the nearest station",
    // Stations: 'Stations',
    headerFooter: {
      Home: "Home",
      AboutUs: "About us",
      Howitworks: "How it works",
      Stations: "Stations",
      ChargingStations: "Charging stations",
      RequestCard: "EV charging card",
      ContactUs: "Contact us",
      About: "About ",
      Additionallinks: "Additional links ",
      infintyAdress:
        "57 Corniche El Maadi, Giza Governorate, Cairo, Cairo Governorate 11431",
      SignIn: "Sign in ",
      viewProfile: "view profile",
      Notifications: "Notifications",
      AllRightsReserved: "All Rights Reserved"
    }
  },
  contactusRequests: {
    requestStatus: "Request status",
    status: "Request status",
    requestDetails: "Request details",
    new: "New",
    closed: "Closed",
    close: "Close",
    needsAction: "Needs action",
    reply: "Reply",
    replyHint: "This reply will be sent directly to customer email",
    commentHint: "This comment can only be seen by admins",
    replyPlaceholder: "Write your request reply here",
    requestUpdatedSuccessfully: "Request status successfully updated"
  },
  userProfile: {
    ChangePassword: "Change password",
    NameMin: "Name min 2 characters",
    NameMax: "Name max is 12 characters",
    Lastname20Max: "Last name cannot be longer than 20 characters",
    Firstname20Max: "First name cannot be longer than 20 characters",
    emailMin: "Email length must be more than 5 characaters",
    emailMax: "Email length must be less than 150 characaters",
    pleaseEntername: "Please enter name",
    minmaxaddress: "Address must be between 3 and 300 characters",
    Name: "Name",
    firstName: "First name",
    lastName: "Last name",
    pleaseEnterValidmail: "Please enter valid mail",
    pleaseEntermail: "please enter mail",
    mail: "mail",
    Pleaseinputyourphonenumber: "phonen number must be 11 num",
    pleaseEnteraddress: "please enter address",
    pleaseSelectGender: "please select Gender",
    Male: "Male",
    Female: "Female",
    ChangePassword: "Change Password",
    PersonalInfo: "Personal Info",
    ChangeYourPassword: "Change your Password",
    Uploadnewpicture: "Upload new picture",
    address: "address",
    Currentpassword: "Current password",
    Newpassword: "New password",
    Confirmnewpassword: "Confirm new password",
    CarInfo: "Electric Car Details",
    CarsInfo: " Cars Info",
    ArrivalAddress: "Delivery Address",
    sameashomeaddress: "Same as home address",
    oneofourcustomers:
      "If you already have an infinity-e account, please sign in first",
    AddmoreCar: "Add more Car",
    selectUserCar: "Please select a car",
    Selectcarbrand: "Select car brand",
    Selectcartype: "Select car type",
    pleaseEntercarbrand: "Please enter car brand",
    cartype: "car type",
    pleaseEntercartype: "Please enter car type",
    pleaseEnterbetteryrange: "Please enter battery range",
    Selectabetteryrange: "Select a bettery range",
    Attachcarlicense: "Attach car license",
    copyofthecarlicense: "A copy of the car license",
    oryoucanattachcustomsreleasecertificate:
      "or you can attach customs release certificate",
    Requests: "Requests",
    NoRequests: "No requests",
    carInfoAdded: "car info added successfully",
    carlicenseNeed: "Please add car license no less than 2 images",
    youwanttocancel: "Are you sure you want to cancel this request?",
    fillTheForm:
      "Please fill out the form below to order your infinity-e charging card",
    carsUpdated: "Car info updated successfully",
    deleteCar: "Delete car",
    carDeleteConfirmation: "Are you sure you want to delete this car ?",
    Createddate: "Created date",
    Status: "Status",
    DeliveryAddress: "Delivery address",
    Action: "Action",
    Welcome: "Welcome",
    foraneasierexperience:
      "For an easier experience make your account better by complete the following data.",
    Continue: "Continue",
    Skipfornow: "Skip for now",
    noLicenceSelected: "Please selected licence images",
    licenseFront: "Please select license front image",
    licenseBack: "Please select license back image",
    Wallet: "Wallet"
  },
  contactUs: {
    fullName: "Full name",
    email: "E-mail",
    RequestDetails: "Subject",
    PleaseinputyourRequestDetails: "Please input your Request Details",
    HereToHelp: "We’re here to help",
    ContactUS: "Contact us",
    ContactDetails: "Contact details",
    OpeningHours: "Opening hours",
    Closed: "Closed",
    About: "About",
    Howitworks: "How it works",
    ChargingStations: "Charging stations",
    RequestCard: "EV charging card",
    Additionallinks: "Additional links",
    AboutUs: "About us",
    subjectMin: "Subject length must be at least 20 characters",
    subjectMax: "Subject maximum length must be 200 characters",
    nameMin: "Name must be at least 2 characters",
    nameMax: "Name can't be more than 60 characters",
    dayopen: "Sunday - Thursday",
    dayclose: "Friday - Saturday"
  },
  notifications: {
    loadMore: "Load more notifications",
    notifications: "Notifications",
    noNotifications: "It's all good, you don't have new notifications",
    today: "Today's"
  },
  aboutUs: {
    aboutUsPragraph:
      "Infinity-e was established in 2018 with the vision to develop Egypt’s electric vehicle charging network infrastructure. We are working towards creating the most reliable and convenient EV charging network in Egypt. infinity-e is a subsidiary of infinity energy, the leading company in the renewable energy sector in Egypt .",
    Exploremore: "Explore more",
    Ourstations: "Our stations",
    OurstationsPragraph1:
      "All infinity-e charging stations are equipped to charge at least 2 electric vehicles simultaneously, while some stations capacitate for up to 6 vehicles. They are equipped with double-socket 22Kw-AC-chargers and fast-charging DC chargers in major stations, providing an output of 50 Kw to charge your electric car up to 125km in 30 minutes. ",
    OurstationsPragraph2:
      "All stations confine to EU standards and cater for type 2 charger cables. Alternatively, one can use an adapter cable to connect to the charging station.",
    Our: "Our",
    Partners: "Partners"
  },
  howItWork: {
    How: "How",
    itworks: "it works",
    Start: "Connect",
    StartStep: "Connect charger cable with car & station.",
    Second: "Start",
    SecondStep: "Start charging session by swiping charging card.",
    Third: "Charge",
    ThirdStep: "Blue lED light indicates start of charging session.",
    End: "Charging Complete",
    EndStep:
      "Stop charging session by swiping charging card then removing the cable.",
    RequestCard: "Charging card",
    Requestcharging:
      "Order a card to be able to charge your car at any infinity-e charging station",
    Search: "Search",
    Searchlocation: "Find your nearest Infinity-e charging station here"
  },
  signIn: {
    WelcomeBack: "Welcome Back !",
    Tokeepconnected:
      "To keep connected with us please login with your personal info",
    SignIn: "Sign In",
    SignUp: "Sign Up",
    HelloFriend: "Hello, Friend!",
    personaldetails: "Enter your personal details and start journey with us",
    SignintoInfinity: "Sign in to infinity",
    Forgotpassword: " Forgot your password ?",
    haveaccount: " Don’t have an account?"
  },
  courses: {
    TodaysCourses: "Today's Courses",
    more:'More',
    Day:'Day',
    Week:'Week',
    Month:'Month',
    All: 'All',
    ToDo:'ToDo',
    Completed:'Completed',
    Draft:'Draft',
    NoCoursesAdded :'No Courses Added',
    allCourses: 'All Courses',
    coursesSubtitle: 'Courses to complete today',
    attachments: 'Attatchments',
    downloadAll: 'Download All',
  },
  filtrationBar: {
    filter: 'Filter',
    subject: 'Subject',
    date: 'Date',
    courseName: 'Course Name',
    subjectPlaceholder: 'Select a subject',
    datePlaceholder: 'Select a date',
    courseNamePlaceholder: 'Type course name',
    allYear: 'All Year',
    clear: 'clear',
    ResultType:'Result Type',
    ResultEvaluation:'Result Evaluation'
  },
  assigments : {
    Assigments : 'Assignments',
    Assignment : 'Assignment',
    NoAssigmentsAdded : 'No Assignments Added',
    Youhave : 'You have ',
    NoFeedbackyet : "No Feedback yet , Complete your assignment first and wait your teacher's feedback",
    SuccessfullyPass : 'Successfully Pass',
    assigmentstocompletetoday : ' assignments to complete today',
    All : 'All',
    ToDo : 'To Do',
    Late : 'Late',
    Feedback : 'Feedback',
    Completed : 'Completed',
    Uncomplet : 'Incomplete',
    open : 'open',
    Feedback : 'Feedback',
    ReSubmit : 'Re-Submit',
    Teacherfeedback : "Teacher's feedback",
    TodayAssigments : "Today's Assignments",
    Applyyourassignmenthere : 'Apply your assignment here..',
    AssignmentReply : 'Assignment Reply',
    doesntmatchtherequirement : "doesn't match the requirement.",
    endDate: 'End Date',
    question: 'Question',
    degree: 'Degree',
    of: 'of',
    inProgress:'in Progress' 

  }, 
  exams:{
    exams:'Exams',
    headerSubtitle:'exams to complete today',
    all : 'All',
    toDo : 'To Do',
    upComing: 'Up Coming',
    previous: 'Previous',
    online: 'Online',
    printed: 'Printed',
    hours: 'Hours',
    hour: 'Hour',
    questions: 'Questions',
    startExam: 'Start Exam',
    instructions: 'Instructions',
    daysLeft: 'Days Left',
    Beforeyoubegin: 'Before you begin',
    Themaximumtime: 'The maximum time that allowed to Access the exam after the start time .',
    Theresnooption: "There's no option to pause. Make sure you will not be interrupted for",
    Ifyouaccidentallyclose: 'If you accidentally close your browser, use the invitation link to get back to your test.',
    Hintyoucanuse : 'Hint: you can use your own IDE and use copy-paste, but make sure your solution compiles in Scholigit Test environment.',
    HowtouseitInstruction: 'How to use it : Instruction and Tips',
    minutes: 'minutes',
    Hours: 'Hours',
    StartTime: 'Start Time',
    EndTime: 'End Time',
    ExamDuration: 'Exam Duration',
    NQuestions: 'N. Questions',
    Questions: 'Questions',
    Accessibilitytime: 'Accessibility time',
    FromstartingTimeTill: 'From starting Time Till',
    checkTipNumberbelow: 'check Tip Number 1 below',
    noExamsFound: 'No Exams Found',
    under: 'Under',
    correction: 'Correction',
    underCorrection: 'Under Correction',
    totalDegree:'Degrees' ,
    final:'Final Exam',
    midterm:'Midterm Exam',
    monthly:'Monthly Exam',
    examTitle:'Exam Title',
    examType:'Exam Type',
    subject:'Subject',
    start:'Start',
    end:'End',

  },
  Results:{
    Results:'Results',
    withinthetotal:'within the total',
    Outoftotal:'Out of total',
    Excellent:'Excellent',
    VGood:'V.Good',
    Good:'Good',
    Congratulations:'Congratulations',
    successfullypassed:', You are successfully passed and transferred to the next Grade.',
    LatestResults:'Latest Results',
    Archive:'Archive',
    NoResultsfoundyet:'No Results found yet',
    Yourresultswillappearhere:'Your results will appear here once the correction is over',
    MoreDetails:'More Details'
  },
  exam: {
    Exam: 'Exam',
    Class: 'Class',
    startExam: 'Start Exam',
    continueExam: 'Continue Exam',
    finishExam: 'Finish Exam',
    next: 'Next',
    previous: 'Previous',
    FinishExam: 'Finish Exam',
    solved: 'Solved',
    NotSolved: 'Not Solved',
    MarkForReview: 'Mark For Review',
    minutes: 'minutes',
    Hours: 'Hours',
    StartTime: 'Start Time',
    EndTime: 'End Time',
    ExamDuration: 'Exam Duration',
    NQuestions: 'N. Questions',
    Questions: 'Questions',
    Accessibilitytime: 'Accessibility time',
    FromstartingTimeTill: 'From starting Time Till',
    checkTipNumberbelow: 'check Tip Number 1 below',
    Beforeyoubegin: 'Before you begin',
    Themaximumtime: 'The maximum time that allowed to Access the exam after the start time .',
    Theresnooption: "There's no option to pause. Make sure you will not be interrupted for",
    Ifyouaccidentallyclose: 'If you accidentally close your browser, use the invitation link to get back to your test.',
    Hintyoucanuse : 'Hint: you can use your own IDE and use copy-paste, but make sure your solution compiles in Scholigit Test environment.',
    HowtouseitInstruction: 'How to use it : Instruction and Tips',
    Areyouready: 'Are you ready ?',
    Theexamisnotvalidanymore : 'The exam is not valid anymore',
    ThisexamURLisnotvalidanymore : 'Oops! This exam URL is not valid anymore, It is for one use only',
    GoToExamsPage : 'Go To Exams Page',
    question: 'Question',
    point: 'Point',
    MultiChoise : 'Multi Choice',
    SingleChoise : 'Single Choice',
    Written : 'Paragraph ',
    Trueorfalse : 'True or false',
    true : 'True',
    false : 'False',
    connect: 'Connect',
    rearrange: 'Re-arrange',
    complete: 'Complete',
    wellDone: 'Well Done',
    youDidGreat: 'You did great',
    yourResult: 'Your result',
    done: 'Done',
    examSubmitted: 'Your Answers have been submitted',
    waitForCorrection: 'Wait for the correction to finish for the exam and you will be notified of the result soon upon completion',
    keepInMind: "Keep in mind that when you click on 'Finish' , that's mean that you have finished your exam and can't refer to your answers again.",
    TextAreaTypeAnswer:'Type your answer here...',
    showAnswer: 'Show Answer' ,
    selectAnswerFirst:'Please answer the question first',
    questionQ:'Q'

  },
  timeline: {
    addPhoto: 'Add Photo',
    addVideo: 'Add Video',
    Photo: 'Photo',
    Video: 'Video',
    File: 'File',
    post: 'Post',
    TimeLine: 'TimeLine',
    Calender: 'Calender',
    Attendens: 'Attendens',
    TimeTable: 'TimeTable',
    HomeWork: 'Home Work',
    Exam: 'Exam',
    createTagline: 'What’s happening in your classroom?',
    captionRequired: 'Story caption is required',
    postCreatedSuccessfully: 'Post created successfully',
    writeyourmessagehere: "write your message here…",
    WholeClass: 'Whole Class',
    SetHomework: 'Set Homework',
    Cancel: 'Cancel',
    cropImage: 'Crop selected image',
    crop: 'Crop',
    imageSize: 'Image size must be less than 10 MB',
    videoSize: 'Video size must be less than 200 MB',
    invalidFormat: 'Please select a valid image or video type',
    invalidWidth: 'Image width must be more than 400 pixel',
    invalidHeight: 'Image height must be more than 400 pixel',
    limitExceeded: "You can't select more than 20 files",
    invalidImages: 'files are invalid',
    DropToUpload: 'Drop files here to upload',
    Upload: 'Upload',
  },
  calendar: {
      day: 'Day',
      week: 'Week',
      month: 'Month',
      calendar: 'Calendar',
      youHave: 'You have',
      remainingTasks: 'remaining tasks',
      HomeWork:'HomeWork',
      OnlineSession:'Online Classroom',
      TimeTable:'Timetable',
      Solve:'Solve',
      Join:'Join',
      Ended:'Ended'
  },
  todoList:{
    todoList:'To Do List',
    TimeTable:'TimeTable',
    HomeWork:'Home Work',
    ClassOnline:'Class Online',
    Time:'Time',
    Title:'Title',
    Topic:'Topic',
    Subject:'Subject',
    Teacher:'Teacher',
    Status:'Status',
    StartTime:'Start',
    DueTime:'Due',
    Done:'Done',
    InProgress:'In Progress',
    Join:'Join',
    TimeRemining:'Time Remining',
    Solve:'Solve Homework',
    empty:'Sorry, there are no todos for this day',
    YouHave:'You Have',
    Coursestocompletetoday:'Courses to complete today',
    to:'To',
    Details:'Details',
    Completed:'Completed',
    Type:'Type',
    OnlineSession:'Online Session'
},
    timetable : {
        Satarday : 'Satarday',
        Sunday : 'Sunday',
        Monday : 'Monday',
        Teusday :'Teusday',
        Wednesday : 'Wednesday',
        Thursday : 'Thursday',
        Session :'Session',
        Sessions : 'Sessions',
    },
    onlineSessions :{
      OnlineSessions:'Online Sessions',
      Courses:'Courses',
      General:'General',
      OnlineSessionTitle:'Session Title',
      Teacher:'Teacher',
      Subject:'Subject',
      Chapter:'Chapter',
      Topic:'Topic',
      Date :'Date ',
      Details:'Details',
      Status:'Status',
      EmptyText:'Oops , there are No Online Sessions ',
      Started:'Started',
      Scheduled:'Scheduled',
      Ended:'Ended',
      Join:'Join',
      TimeRemaining:'Time Remaining',
      Done:'Done',
      Cancel:'Cancel',
      onlineSession: 'Online Session',
      notStarted: 'Session has not started yet',
      emptyFiles: 'There are no files in this session',
      processing: 'Processing...',
      general: 'General',
      All:'All'

  }
};
