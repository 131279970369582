import React from 'react'
import Loadable from 'react-loadable'
import { Spin } from 'antd';
import LoadingIndicator from '../Common/components/LoadingIndicator/LoadingIndicator';

/**
 * Defines module routes, you can use react-router-dom route parameters
 * as properties in each object in routes array for example "exact: true"
 * 
 * Each route lazy loads a component if its path matches the current path. 
 */

const nestedRoutes = [
    {
        path: '/admins',
        component: './views/Admins/Admins'
    },
    {
        path: '/masterdata/carbrand',
        component: './views/MasterData/MasterData'
    },
    {
        path: '/masterdata/cartype',
        parentType: 'carbrand',
        component: './views/MasterData/MasterData'
    },{
    
        path: '/chargerManagement',
        component: './views/Stations/Stations'
    },
    {
        path: '/masterdata/batteryrange',
        component: './views/MasterData/MasterData'
    },
    {
        path: '/users',
        component: './views/Users/Users' 
    },
    {
        path: '/Contactusrequests',
        component: './views/ContactUsRequests/ContactUsRequests' 
    },
    {
        path: '/cardrequests',
        component: './views/Cards/Cards' 
    },
    {
        path: '/chargerspricingsettings',
        component: './views/ChargersPricingSettings/ChargersPricingSettings' 
    },
    {
        path: '/roles',
        component: './views/Roles/Roles'
    }
    ,
    {
        path: '/settings',
        component: './views/Profile/Profile' 
    },
    {
        path: '/countries',
        component: './views/Countries/Countries'
    },
    {
        path: '/governorates',
        component: './views/Governorates/Governorates'
    },
    {
        path: '/home',
        component: './views/Home/Home'
    },
    {
        path: '/courses',
        component: './views/StudentCourses/StudentCourses'
    },
    {
        path: '/courseDetails',
        component: './components/CourseDetails/CourseDetails'
    },
    {
        path: '/assignments',
        component: './views/StudentAssignments/StudentAssignments' 
    },
    {
        path: '/assignmentDetails',
        component: './components/AssignmentsDetails/AssignmentsDetails' 
    },
    {
        path: '/exams',
        component: './views/StudentExams/StudentExams'
    },
    {
        path: '/results',
        component: './views/StudentResults/StudentResults'
    },{
        path: '/schedule',
        component: './views/Schedule/Schedule'
    },
    {
        path: '/calendar',
        component: './views/StudentCalendar/StudentCalendar'
    },
    {
        path: '/timetable',
        component: './views/StudentTimetable/StudentTimetable'
    },
    {
        path: '/onlinesessions',
        component: './components/StudentOnlineSessionList/StudentOnlineSessionList'
    },
    {
        path: '/onlinesessionDetails',
        component: './components/OnlineSessionDetails/OnlineSessionDetails'
    }
]

const routes = [
    {
        path: '/student',
        isAuth: true,
        nestedRoutes,
        allowedRoles: [],
        component: Loadable({
            loader: () => import('./Dashboard'),
            loading: () => (<LoadingIndicator/>)
        })
    },
    {
        path: '/',
        isAuth: true,
        exact: true,
        allowedRoles: [],
        component: Loadable({
            loader: () => import('./../index'),
            loading: () => (<LoadingIndicator/>)
        })
    },
]

export default routes