export const responseMessages = {
    200:    'Success',
    100:	'Failed To Send Code',
    101:	'Invalid Code',
    102:	'Verification Code Required',
    103:	'SMS Interval Send Violation',
    104:	'User not Found',
    105:	'Validation Error',
    106:	'Registration Field Already Exist',
    107:	'Forget Password Field Required',
    108:	'Phone Number Already Exist',
    109:	'Invalid Refresh Token',
    110:	'Email Not Confirmed',
    111:	'Phone Number Not Confirmed',
    112:	'Maximum SMS Send Code Reached',
    113:	'Phone Number Required',
    114:	'Email Required',
    115:	'Email Already Exists',
    116:	'Wrong Email or password',
    117:	'Wrong Email or password',
    118:	'Custom Text Required',
    119:	'Custom DropDown Required',
    120:	'Invalid Client',
    121:	'Failed To Update User',
    122:	'Invalid Facebook Token',
    123:	'Facebook Token Deosnt Exist',
    124:	'Wrong Email or password',
    125:	'Google Token Deosnt Exist',
    126:	'Invalid Google Token',
    127:	'Invalid Registration Type',
    128:	'Invalid Login Type',
    129:	'Social User Exist',
    131:    'Invalid reset token',
    138:    'Old password is incorrect',
}