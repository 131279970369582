export const SET_COURSES_FILTRATION = 'SET_COURSES_FILTRATION'
export const SET_DRAWER_ACTIVE_TAB = 'SET_DRAWER_ACTIVE_TAB'
export const SET_COURSE_DETAILS = 'SET_COURSE_DETAILS'
export const SET_FILTRATION_RESULT_VISIBILITY = 'SET_FILTRATION_RESULT_VISIBILITY'
export const SET_FILTRATION_BAR_VISIBILITY = 'SET_FILTRATION_BAR_VISIBILITY'
export const SET_FILTRATION_ASSIGNMENT_DATE = 'SET_FILTRATION_ASSIGNMENT_DATE'

export default {
    SET_DRAWER_ACTIVE_TAB,
    SET_COURSES_FILTRATION,
    SET_COURSE_DETAILS,
    SET_FILTRATION_RESULT_VISIBILITY,
    SET_FILTRATION_BAR_VISIBILITY,
    SET_FILTRATION_ASSIGNMENT_DATE,
}