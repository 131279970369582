import { postResource, getResource } from "../../network";

/**
 * Gets a list of user notifications
 * @param {Object} body 
 * @param {Function} onSuccess 
 * @param {Function} onError 
 */
export function getUserNotifications(body, onSuccess, onError) {
    postResource('/notification/api/Notification/GetUserNotifications', JSON.stringify(body), (res) => {
        onSuccess(res)
    }, (fail) => {
        onError(fail.errors)
    })
}

export function setNotificationAsSeen(query, onSuccess, onError) {
    postResource(`/notification/api/Notification/SetAsSeen?NotificationID=${query}`, JSON.stringify({}) ,(res) => {
        onSuccess(res)
    }, (fail) => {
        onError(fail.errors)
    })
}

export function setDeviceToken(query, onSuccess, onError) {
    postResource(`/api/Auth/AddDeviceTOken?Token=${query}`,  JSON.stringify({}),(res) => {
        onSuccess(res)
    }, (fail) => {
        onError(fail.errors)
    })
}

export function getNotificationsCount(onSuccess, onError){
    getResource('/notification/api/Notification/GetNotificationsCount', (res) => {
        onSuccess(res)
    }, (fail) => {
        onError(fail.errors)
    })
}

export function generateUploadFileKey(body, onSuccess, onError) {
    postResource('/filesservice/api/Files/GenerateUploadFileKey', JSON.stringify(body), (res) => {
        onSuccess(res)
    }, (fail) => {
        onError(fail.errors)
    })
}

export function uploadFileChunk(body, onSuccess, onError) {
    postResource('/filesservice/api/Files/UploadFileChunk', body, (res) => {
        onSuccess(res)
    }, (fail) => {
        onError(fail.errors)
    }, true)
}

export function buildFileChunks(body, onSuccess, onError) {
    postResource('/filesservice/api/Files/BuildFileChunks', JSON.stringify(body), (res) => {
        onSuccess(res)
    }, (fail) => {
        onError(fail.errors)
    })
}

export function loginAs(body, onSuccess, onError) {
    postResource('/api/Auth/LoginAs', JSON.stringify(body), (res) => {
        onSuccess(res)
    }, (fail) => {
        onError(fail.errors)
    })
}

export function sendEvents(body, onSuccess, onError) {
    postResource('/NavigationTrackingLogger/LogBulk', JSON.stringify(body), (res) => {
        onSuccess(res)
    }, (fail) => {
        onError(fail.errors)
    }, false, true)
}