import {
	postResource,
	getResource,
	testPostResource,
	testGetResource,
} from "../../network";

export function getUserInfo(onSuccess, onError) {
	getResource(
		`/api/Account/GetUserInfo`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function updateUserInfo(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/Parent/UpdateProfileInfo",
		body,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail);
		},
		true
	);
}

export function changePassword(body, onSuccess, onError) {
	postResource(
		"/api/Account/ChangePassword",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function changePasswordWithValidation(body, onSuccess, onError) {
	postResource(
		"/api/Account/ChangePasswordV2",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail);
		}
	);
}

/** Courses Requests */

export function getStudentCourses(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/Materials/GetStudentMaterials",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getFilteredStudentCourses(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/Materials/GetStudentFilteredMaterials",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

/** Assignments Requests */

export function getStudentAssignments(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/Homeworks/GetStudentHomeworks",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function addAssignmentAnswer(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/HomeworkSessions/AddHomeworkSessionAnswer",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function updateAssignmentAnswer(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/HomewrokAnswers/UpdateHomeworkAnswer",
		body,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		},
		true
	);
}

export function getAssignmentDetails(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/HomeworkSessions/StartHomeworkSession",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

/** Utils */

export function getSubjectsList(onSuccess, onError) {
	getResource(
		"/scholigit/api/Subject/GetStudentSubjects",
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getStudentDetails(onSuccess, onError) {
	getResource(
		"/scholigit/api/Student/GetStudentProfile",
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getStudentExamsList(body, onSuccess, onError) {
	postResource(
		"/examapi/api/Exams/GetStudentExams",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function generateUploadFileKey(body, onSuccess, onError) {
	postResource(
		"/filesservice/api/Files/GenerateUploadFileKey",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function uploadFileChunk(body, onSuccess, onError) {
	postResource(
		"/filesservice/api/Files/UploadFileChunk",
		body,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		},
		true
	);
}

export function buildFileChunks(body, onSuccess, onError) {
	postResource(
		"/filesservice/api/Files/BuildFileChunks",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function submitHomeworkSession(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/HomeworkSessions/SubmitHomeworkSession",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.error);
		}
	);
}

export function getStudnetTodoList(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/Student/GetStudentToDoList",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.error);
		}
	);
}

export function getStudnetCalendarList(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/Student/GetStudentCalender",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.error);
		}
	);
}

export function joinOnlineSession(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/OnlineSessions/Join",
		/*"/api/OnlineSessions/Join"*/ JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getStudentOnlineSessionDetails(query, onSuccess, onError) {
	getResource(
		`/scholigit/api/OnlineSessions/GetStudentOnlineSessionDetails?SessionID=${query}`,
		/*`/api/OnlineSessions/GetStudentOnlineSessionDetails?SessionID=${query}`*/ (
			res
		) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getStudentOnlineSessionsList(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/OnlineSessions/GetStudentOnlineSessionsList",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}
export function LikeOnCourse(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/Materials/UpdateMaterialLikeStatus",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}
export function ViewOnCourse(body, onSuccess, onError) {
	postResource(
		`/scholigit/api/Materials/UpdateVideoView?VideoId=${body}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}
