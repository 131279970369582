import {
    SET_LOCALE,
    UPDATE_CONNECTION_STATUS,
    LIST_USER_NOTIFICATIONS,
    SET_NOTIFICATION_STATUS,
    PREPEND_NEW_NOTIFICATION,
    SET_NOTIFICATIONS_COUNTER,
    LOG_EVENT,
    RESET_LOGS,
    UPDATE_EVENT_DURATION
} from './action-types'

export function setLocale(payload){
    return {
        type: SET_LOCALE,
        payload,
    }
}

export function updateConnectionStatus(payload){
    return {
        type: UPDATE_CONNECTION_STATUS,
        payload,
    }
}

export function listUserNotifications(payload){
    return {
        type: LIST_USER_NOTIFICATIONS,
        payload
    }
}

export function setNotificationStatus(payload){
    return {
        type: SET_NOTIFICATION_STATUS,
        payload
    }
}

export function prependNewNotification(payload) {
    return {
        type: PREPEND_NEW_NOTIFICATION,
        payload
    }
}

export function setNotificationsCounter(payload) {
    return {
        type: SET_NOTIFICATIONS_COUNTER,
        payload
    }
}

export function logEvent(payload){
    return {
        type: LOG_EVENT,
        payload
    }
}

export function updateEventDuration(payload){
    return {
        type: UPDATE_EVENT_DURATION,
        payload
    }
}

export function resetLogs(){
    return {
        type: RESET_LOGS
    }
}