import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { useLocation } from "react-router-dom"
import routes from './routes';
import { notification } from "antd";
import {messaging} from '../resources/firebase.js' 
import {createBrowserHistory} from 'history'
import {connect} from 'react-redux'
import ReactGA from "react-ga";
import Error404 from '../modules/Errors/components/Error404/Error404';
import { setDeviceToken } from '../modules/Common/service';
import store from '../store';
import { prependNewNotification } from '../modules/Common/store/actions';
import moment from 'moment';
import notificationSound from '../resources/notification-sound.mp3'

const history = createBrowserHistory()

history.listen(location => {
	ReactGA.set({ page: location.pathname })
	ReactGA.pageview(location.pathname)
})

/**
 * Detects if user is idle within a specific amout of ms
 * @param {*} ms 
 * @param {*} cb 
 */
function detectIdle(ms, cb) {
    var wait = setTimeout(cb, ms);
    document.onfocus = document.onmousedown = document.onmouseenter = document.onmouseleave = document.onkeydown = document.onkeypress = document.onkeyup = function (e) {
		clearTimeout(wait);
        wait = setTimeout(cb, ms);
	};
	document.addEventListener("visibilitychange", function() {
		if(document.hidden){
			clearTimeout(wait);
		}
	})
}

/**
 * Returns a public component
 * @param {Object} props 
 */

const PublicRoute = ({ isAuthenticated, component: Component, ...rest}) => {
	const { pathname } = useLocation();
	useEffect(() => {
		document.body.scrollTop = 0
		ReactGA.pageview(window.location.pathname)
	}, [pathname])
	return (<Route {...rest} render={props => (<Component {...props} allowedType={rest.allowedType}/>)} />)		

}

/**
 * Returns a private component if user is authenticated and have sufficient permissions
 * @param {Object} props 
 */
const PrivateRoute = ({ component: Component, isAuthenticated, roles, ...rest }) => {
	const [isIdle, setIdleState] = useState(false);
	const { pathname } = useLocation();
	useEffect(() => {
		document.addEventListener("visibilitychange", function() {
			if (!document.hidden){
				detectIdle(1800000, function () {
					setIdleState(true)
				})
			}
		})
		document.body.scrollTop = 0
		ReactGA.pageview(window.location.pathname)
	}, [pathname])

	
	return (
		<Route {...rest} render={props => (
			!isIdle ? (isAuthenticated
				? (
					(roles ? rest.allowedRoles.every(role => roles.indexOf(role) > -1) : true) ? 
					<Component allowedType={rest.allowedType} nestedRoutes={rest.nestedRoutes} path={rest.path} {...props} /> : <Redirect to={{
						pathname: '/error401',
						state: { from: props.location }
					}}/>
				)
				: <Redirect to={{
					pathname: '/signin',
					state: { from: props.location },
				}} />) : <Redirect to={{
					pathname: '/timed-out',
					state: { from:  props.location}
				}}/>
		)} />
		
	)
}

const Routes = ({isAuthenticated, roles}) => {
	return (
	<Router hisotry={history}>
		<Switch>
			{routes.map((route, i) => {
			if(route.isAuth){
				return (
				<PrivateRoute roles={roles} isAuthenticated={isAuthenticated} key={i} {...route} />
				)
			}
			return( <PublicRoute isAuthenticated={isAuthenticated} roles={roles} key={i} {...route} />)
			})}
			<Route component={Error404}/>
		</Switch>
	</Router>
)}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    roles: state.auth.userRoles
  }
}

export default connect(mapStateToProps)(Routes)
