import * as firebase from "firebase/app";
import "firebase/messaging";

var firebaseConfig = {
    apiKey: "AIzaSyD45pqBTY1wlSUJquEtPZraInR2XlRduE8",
    authDomain: "infinitye-266015.firebaseapp.com",
    databaseURL: "https://infinitye-266015.firebaseio.com",
    projectId: "infinitye-266015",
    storageBucket: "infinitye-266015.appspot.com",
    messagingSenderId: "911107012736",
    appId: "1:911107012736:web:147864e0634f6f74a94e65"
}

var messaging

if(!firebase.messaging.isSupported()){
  messaging = null
  console.log('Your browser does not support push notifications')
}
else {
  const initializedFirebaseApp = firebase.initializeApp(firebaseConfig)
  messaging = initializedFirebaseApp.messaging();
  messaging.usePublicVapidKey(
    "BPVejBNXhk0_k-sFk2EX-l3J84nsxS4ABWQVN-t4F4y72_zHV3HQuzkdW-PM3boYI-JL4t0QwZ3OTC3RjTZ4oBA"
  );

}

export { messaging }