import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import registerServiceWorker from './registerServiceWorker';
import * as Sentry from '@sentry/browser';
import ReactGA from 'react-ga'

// Sentry.init({dsn: "https://f52bc8197df8452dab17cd3fdf9aee8c@o464051.ingest.sentry.io/5476337"});

ReactGA.initialize('UA-113313413-8', {
    gaOptions: {
        userId: window.sessionStorage.getItem('userid')
    }
})


ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// registerServiceWorker()
