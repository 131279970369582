export const ar = {
    auth: {
        login: 'تسجيل الدخول',
        logout: 'تسجيل الخروج',
        email: 'بريد الكتروني',
        rememberMe: 'تذكرني',
        signIn: 'تسجيل الدخول',
        password_placeholder: 'ادخل رقمك السري',
        mail_massage:'هذا البريد الالكتروني غير صالح',
        mail_req_massage:'الرجاء ادخال بريدك الالكتروني',
        password:'الرقم السري',
        forgetPassword:'نسيت كلمة السر',
        Forget_Password:'نسيت كلمة السر',
        sammary:'برجاء ادخال البريد الالكتروني لتتمكن من اعاده تعيين كلمة السر',
        passwordMassage:'الرجاء ادخال كلمة السر',
        backToSignin:'الرجوع الي تسجيل الدخول',
        sendMail:'ارسال بريد الكتروني',
        sorry403:'نأسف ، لكن ليس لديك حق الوصول إلى هذه الصفحة أو المورد.',
        sorry505:'واجه الخادم خطأ داخليًا أو خطأ في التكوين وكان كذلك لا يمكن إكمال طلبك',
        resetSent: 'تم إرسال كود إعادة التعيين بنجاح',
        plaeaseEnterMail:'الرجاء إدخال بريدك الإلكتروني ثم انقر فوق الزر أدناه لطلب واحد آخر.',
        confirmPassword:'تأكيد كلمة المرور الجديدة',
        restPass_placeholder:'اعد كتابه رقم المرور',
        confirm_massage:'الرجاء تاكيد كلمة السر',
        Reset:'إعادة تعيين',
        cancle:'الغاء',
        CheckInpoxp1:'يرجى التحقق من مجلد البريد الوارد أو البريد العشوائي في',
        CheckInpoxp2:'للحصول على رابط لإعادة تعيين كلمة المرور الخاصة بك.',
        GoToMail:'اذهب الي البريد الالكتروني',
        Ok:'حسنا',
        goToHome:'اذهب الي الصفحه الرئيسيه',
        oops:'عفوا !!عنوان URLغير صحيح',
        ResendMail:'اعاده ارسال البريد الالكتروني',
        passwordmin:'يجب أن تكون كلمة المرور أقل من 6 أحرف',
        passwordmax:'يجب أن تكون كلمة المرور 20 حرفًا كحد أقصى',
        passwordexpresion:'يجب أن تتكون كلمة المرور من 8 أحرف على الأقل بما في ذلك الأحرف الكبيرة والصغيرة.',
        profile:'الصفحه الشخصية',
        personalInfo:'معلومات شخصية',
        name:'الاسم',
        nameMassage:'الرجاء ادخال الاسم',
        namePlaceholder:'ادخل اسمك',
        Security:'الحمايه',
        Account:'حساب',
        Change:'تغيير',
        Deactivate:'تعطيل',
        DeactivateAccount:'تعطيل الحساب',
        SaveChanges:'حفظ التغييرات',
        ChangePassword:'تغيير الرقم السري',
        NoCancel:'الغاء',
        YesDeactivate:'حسنا , قم بتعطيل الحساب',
        PasswordsChanged:'تم تغيير الرقم السري',
        successfullyDeleted:' تم الحذف بنجاح',
        AccountDeactivated:'تم تعطيل الحساب',
        YourAccountDeactivated:'تم إلغاء تنشيط حسابك ، يرجى العلم بأنه لن تتمكن أبدًا من تسجيل الدخول مرة أخرى أو استعادة حسابك',
        Delete:'مسح',
        JoinYourClassNow: "انضم إلى صفك الآن!",
        Studentcode: "كود الطالب",
        Studentcoderequired: "يرجى إدخال كود الطالب",
        AreYouSoure:'هل انت متاكد انك تريد مسح هذه المعلومات',
        YesSave:'نعم, احفظ',
        NoSave:'لا تحفظ',
        Cancel:'الغاء',
        EnterPassFor:'الرجاء ادخال الرقم السري الخاص ب',
        TimedOut:'لقد انتهت مهلتك بسبب الخمول. يرجى اختيار البقاء تم تسجيل الدخول أو تسجيل الخروج. خلاف ذلك ، سوف يتم تسجيلك أوتوماتيكيا بعد 1 دقيقة.',
        SinginOrLogout :'تم تسجيل الدخول أو تسجيل الخروج',
        otherwise:'خلاف ذلك ، سوف يتم تسجيلك أوتوماتيكيا ',
        minute:'بعد 1 دقيقة.',
        stay:'ابقي',
        inactivityMessage: 'لقد تم تسجيل الخروج بسبب عدم النشاط',
        Imagemustsmallerthan5MB : 'يجب أن تكون الصورة أصغر من 5 ميغابايت!',
        YoucanonlyuploadJPGPNG : 'يمكنك فقط تحميل ملف JPG / PNG!',
        createAccount: 'حساب جديد',
        aleadyHaveAccount: 'لديك حساب بالفعل ؟',
        signUp: 'إنشاء حساب',
    },
    notifi: {
        MarkAll:'تحديد قراءه الكل',
        NotifiContent:'مرحبًا بك مرة أخرى في لوحة إدارة مختبرات Starwallet',
        fiveMin:'خمسه دقائق',
        Delete:'مسح',
    },
    validationMessages: {
		shortDescriptionLength: "الوصف المختصر يجب أن لا يتجاوز 70 حرف",
		descriptionLength: "الوصف يجب أن لا يتجاوز 500 حرف",
		titleLength: "العنوان يجب أن لا يتجاوز 30 حرف",
		titleLengthMax: "العنوان يجب أن لا يتجاوز 60 حرف",
		titleLengthMin: 'العنوان يجب أن يكون 3 أحرف على الأقل',
		firstNameLength: "الأسم الأول يجب أن لا يتجاوز 20 حرف",
		firstNameSpaces: "الأسم الأول يجب أن لا يحتوى على أكثر من مسافة واحدة",
		AreYouSureDelete: 'هل أنت متأكد من حذف ',
        deleteFile: 'حذف ملف',
        fileSizeExceed: "حجم الملف لا يمكن أن يتجاوز ",
		fileNotSupported: ' نوع الملفات غير مدعوم',
		imageSize: 'حجم الصورة يجب أن يكون أقل من 10 ميجابايت',
        videoSize: 'حجم الفيديو يجب أن يكون أقل من 200 ميجابايت',
        invalidFormat: 'يرجى إختيار صيغة صورة أو فيديو صحيحة',
        invalidWidth: 'عرض الصورة يجب أن يكون أكبر من 400 بكسل',
        invalidHeight: 'طول الصورة يجب أن يكون أكبر من 400 بكسل',
        limitExceeded: "لا يمكن إختيار أكثر من 20 ملف",
        invalidImages: 'صور غير صالحة'
	},
    home: {
        exportExcel: 'تحميل البيانات'
    },
    header : {
        Search : 'بحث...',
        Editprofile : 'تعديل الملف الشخصي',
        SUPERADMIN : 'مدير',
        NOTIFICATIONS : 'اشعارات',
        Seeall : 'اظهار الكل'
    },
    errors:{
        Unauthorized:'غير مصرح',
        sorry401:'ناسف لا يمكن تنفيذ هذه العمليه',
        goToHome:'اذهب الي الصفحه الرئيسيه',
        sorry403:'نأسف ، لكن ليس لديك حق الوصول إلى هذه الصفحة أو المورد.',
        Access:'غير مسموح بالدخول',
        notFound:'لم يتم الوصول للصفحه',
        error_text:'الصفحه التي تبحث عنها ربما تكون قد تم حذفهااو تم تغيير اسمها او غير متاحه حاليا',
        serverError:'خطا بالخادم',
    },
    MScreens:{
        LinkExpired:'انتهت صلاحية الرابط',
        plaeaseEnterMail:'الرجاء إدخال بريدك الإلكتروني ثم انقر فوق الزر أدناه لطلب واحد آخر.',
        email: 'بريد الكتروني',
        mail_massage:'هذا البريد الالكتروني غير صالح',
        mail_req_massage:'الرجاء ادخال بريدك الالكتروني',
        ResendMail:'اعاده ارسال البريد الالكتروني',
        oops:'عفوا !!عنوان URLغير صحيح',
        Ok:'حسنا',
        PasswordReset:'تم إعادة تعيين كلمات المرور بنجاح',
        ResetPassword:'تعيين رقم سري جديد',
        NewPassword:'رقم سري جديد',
        passwordMassage:'الرجاء ادخال كلمة السر',
        passwordmin:'يجب أن تكون كلمة المرور أقل من 8 أحرف',
        passwordmax:'يجب أن تكون كلمة المرور 20 حرفًا كحد أقصى',
        passwordexpresion:'يجب أن تتكون كلمة المرور من 8 أحرف على الأقل بما في ذلك الأحرف الكبيرة والصغيرة.',
        password_placeholder: 'ادخل رقمك السري',
        confirmPassword:'تأكيد كلمة المرور الجديدة',
        confirm_massage:'الرجاء تاكيد كلمة السر',
        restPass_placeholder:'اعد كتابه رقم المرور',
        cancle:'الغاء',
        Reset:'إعادة تعيين',
        CheckInpoxp1:'يرجى التحقق من مجلد البريد الوارد أو البريد العشوائي في',
        CheckInpoxp2:'للحصول على رابط لإعادة تعيين كلمة المرور الخاصة بك.',
        goToHome:'اذهب الي الصفحه الرئيسيه',
        backToSignin:'الرجوع الي تسجيل الدخول',
    },
    settings:{
        PasswordsChanged: "تم تغيير الرقم السري",
		successfullyDeleted: " تم الحذف بنجاح",
		ChangePassword: "تغيير الرقم السري",
		NewPassword: "رقم سري جديد",
		passwordMassage: "الرجاء ادخال كلمة السر",
		passwordmin: "يجب أن تكون كلمة المرور 8 أحرف على الأقل",
		passwordmax: "يجب أن تكون كلمة المرور 20 حرفًا كحد أقصى",
		passwordexpresion:
			"تعبير كلمة المرور الذي يتطلب حرفًا صغيرًا واحدًا وحرفًا كبيرًا واحدًا ورقمًا واحدًا",
		password_placeholder: "ادخل رقمك السري",
		confirmPassword: "تأكيد كلمة المرور الجديدة",
		confirm_massage: "الرجاء تاكيد كلمة السر",
		restPass_placeholder: "اعد كتابه رقم المرور",
		logout: "تسجيل الخروج",
		AccountDeactivated: "تم تعطيل الحساب",
		YourAccountDeactivated:
			"تم إلغاء حسابك ، يرجى العلم بأنه لن تتمكن أبدًا من تسجيل الدخول مرة أخرى أو استعادة حسابك",
		YesSave: "نعم, احفظ",
		NoSave: "لا تحفظ",
		Cancel: "إلغاء",
		Delete: "مسح",
		SaveChanges: "حفظ التغييرات",
		AreYouSoure: "هل تريد حفظ البيانات ؟",
		signIn: "تسجيل الدخول",
		EnterPassFor: "الرجاء ادخال الرقم السري الخاص ب",
		password: "الرقم السري",
		passwordMassage: "الرجاء ادخال كلمة السر",
		passwordmin: "يجب أن تكون كلمة المرور 8 أحرف على الأقل",
		passwordmax: "يجب أن تكون كلمة المرور 20 حرفًا كحد أقصى",
		passwordexpresion:
			"تعبير كلمة المرور الذي يتطلب حرفًا صغيرًا واحدًا وحرفًا كبيرًا واحدًا ورقمًا واحدًا",
		password_placeholder: "ادخل رقمك السري"
    },



    setting:{
        Settings : 'الإعدادات',
        Integratedappsmasterata : 'التطبيقات المتكاملة البيانات الرئيسية',
        GoogleRECAPTCHA  : 'جوجل RECAPTCHA',
        GoogleAnalyticsTrackingCode  : 'جوجل تتبع تحليلات مدونة',
        FacebookAppID  : 'معرف تطبيق Facebook',
        FacebookAppSecret  : 'Facebook App Secret',
        LinkedInAppID  : 'لينكدين معرف التطبيق',
        EmailedLinksSettings  : 'إعدادات ارتباطات بالبريد الإلكتروني',
        Emailverification  : 'تأكيد بواسطة البريد الالكتروني',
        Enable  : 'مكن',
        Emailverificationlinkexpirytimeminutes  : 'وقت انتهاء صلاحية التحقق من البريد الإلكتروني (بالدقائق)',
        Mins  : 'الدقائق',
        EmailNotifications  : 'اشعارات البريد الالكتروني',
        Resetpasslinkverificationlinkexpirytimeminutes : 'إعادة تعيين رابط انتهاء صلاحية رابط التحقق (بالدقائق)',
        Resendmailinterval  : 'إعادة إرسال الفاصل الزمني للبريد',
        Tries : 'المحاولات',
        Xplacholder : 'xxxx-xxxx-xxxx'
    },

    Privileges : {
        AddRole : 'إضافة دور',
        Newrole : 'دور جديد',
        Personalinfo : 'معلومات شخصية',
        Rolename : 'اسم الدور',
        ISACTIVE : 'نشط',
        Hidetranslations : 'إخفاء الترجمات',
        TranslateIntoAr : 'ترجم إلى “عربي“',
        TranslateIntoFr : 'ترجمة إلى "اللغة الفرنسية"',
        Enable  : 'مكن',
        Rolestatus  : 'حالة الدور',
        Privileges  : 'امتيازات',
        Privilege  : 'امتيازات',
        AddPrivileges  : 'إضافة الامتيازات',
        SelectPrivileges  : 'اختر الامتيازات',
        PleaseselectPrivilegestoAssign  : 'يرجى اختيار الامتيازات لتخصيصها',
        AssignedPrivileges  : 'الامتيازات المخصصة',
        Admins  : 'المسؤولين',
        AddAdmins  : 'إضافة المسؤولين',
        SelectAdmins  : 'حدد المسؤولين',
        PleaseselectAdminstoAssign  : 'يرجى اختيار المسؤولين لتعيين',
        deletedSuccessfully: 'تم حذف الأدوار بنجاح',
        editedSuccessfully: 'تم تعديل الدور بنجاح',
        addedSuccessfully: 'تم إضافة دور جديد بنجاح',
    },

    currency : {
        Currencyname : 'اسم العملة',
        Addcurrency : 'اضف عملة',
        Newcurrency : 'عملة جديدة',
        currencyinfo : 'معلومات العملة',
    },

    users : {
        USERS : 'المستخدمين',
        Name : 'الاسم',
        Age : 'السن',
        Email: 'البريد الإلكتروني',
        Address : 'العنوان',
        Admins : 'المسؤولين',
        UpdateAdmins : 'تعديل المسؤولين',
        Adduser : 'إضافة مسؤول',
        Personalinfo : 'معلومات شخصية',
        Rules : 'الأدوار',
        Addrule : 'أضف الدور',
        Selectrule : 'اختر الدور',
        PleaseselectruletoAssign : 'يرجى اختيار دور لتعيين',
        Assignedrules : 'الأدوار المخصصة',
        deletedSuccessfully: 'تم حذف المستخدمين بنجاح',
        editedSuccessfully: 'تم تعديل المستخدم بنجاح',
        addedSuccessfully: 'تم إضافة مستخدم جديد بنجاح'
    },
    grid : {
        New : 'جديد ',
        goTo: 'الذهاب لصفحة',
        gridSearch: 'بحث',
        reset: 'إعادة تعيين',
        sureToCancel: 'هل أنت متأكد من الحذف؟',
        no: 'لا',
    },
    buttons:{
        Close : 'أغلق',
        SaveNew : 'حفظ و جديد',
        Save : 'حفظ',
        Cancel : 'إلغاء',
        UpdateNew : 'تحديث و جديد',
        Update : 'تحديث',
        Duplicate : 'كرر',
        Delete : 'حذف',
        View : 'عرض',
        Archive : 'أرشيف',
        Edit : 'تعديل',
        confirm: 'هل أنت متأكد من أن تقوم بال',
        selectToEdit: 'يرجى إختيار صف للتعديل',
        selectToDelete: 'يرجى إختيار صف أو أكثر للحذف',
        change:'تغيير',
        remove: 'حذف',
        done: 'تم',
        clear: 'إزالة',
        Send: 'ارسال',
        qu:'؟',
        deleteTheRest: 'حذف البقية',
        selectAll: 'إختر الكل',
        add: 'إضافة',
        YesCancel:'نعم الغي',
        Yes:'نعم ',
        No:'لا',
        NoDontCancel:'لا, لا تلغي',
        crop: 'قص',
        rotate: 'تدوير',
        open: 'عرض',
        Answer:'اجب'

    },

    inputs : {
        inputrequired : 'الحقل مطلوب',
        PleaseinputyourFirstname : 'يرجى إدخال اسمك الأول!',
        PleaseinputyourMiddlename : 'الرجاء إدخال اسمك الأوسط!',
        PleaseinputyourLastName : 'يرجى إدخال اسم العائلة الخاص بك!',
        Pleaseinputyourphonenumber : 'يرجى إدخال رقم هاتفك!',
        Firstname : 'الاسم الاول',
        Middlename : 'الاسم الأوسط',
        LastName : 'اسم العائلة',
        PhoneNumber : 'رقم الهاتف',
        phonemin: "الهاتف لا يمكن أن يكون أقل من 8 أرقام",
        phonemax: "الهاتف لا يمكن أن يتجاوز 11 رقم",
        phone : 'هاتف',
        Email : 'البريد الإلكتروني',
        TheinputisnotvalidEmail : 'الإدخال غير صالح البريد الإلكتروني!',
        PleaseinputyourEmail : 'يرجى إدخال البريد الإلكتروني الخاص بك!',
        EmailAlreadyExists : 'البريد الالكتروني موجود بالفعل',
        Firstnamemustcharacters:"يجب أن يكون الاسم  3 أحرف على الأقل",
        namecantacceptspace:"الاسم لا يمكن أن يقبل مسافة",
        Namecanacceptspecialcharacters:"لا يمكن أن يقبل الاسم الأحرف الخاصة",
        Namecanacceptnumbers:"الاسم لا يمكن أن يقبل الأرقام",
        passwordDoesnotMatch: 'كلمة المرور و التأكيد غير متطابقين'
    },
    masterData: {
        basicInfo: 'البيانات الأساسية',
        label: 'البيانات الرئيسية',
        add: 'إضافة',
        name : 'الإسم',
        email: 'البريد الإلكتروني',
        value: 'القيمة',
        isActive : 'هل مفعل ؟',
        active: 'مفعل',
        inActive: 'غير مفعل',
        translate: 'الترجمة',
        hideTranslations : 'إخفاء الترجمة',
        nameMaxLength: 'الإسم يجب أن لا يتجاوز 60 حرف',
        nameTranslationMaxLength: 'ترجمة الإسم يجب أن لا تتجاوز 60 حرف',
        nameMinLength: 'الإسم يجب أن لا يقل عن 3 أحرف',
        nameTranslationMinLength: 'ترجمة الإسم يجب أن لا تقل عن 3 أحرف',
        namePlaceholder: 'ضع الإسم هنا',
        valuePlaceholder: "ضع القيمة هنا مثال: 25",
        nameTranslationPlaceholder: 'ضع ترجمة الإسم هنا',
        deletedSuccessfully: 'تم حذف البيانات بنجاح',
        editedSuccessfully: 'تم تعديل البيانات بنجاح',
        addedSuccessfully: 'تم إضافة البيانات بنجاح',
        theFollowingData: 'البيانات التالية ',
        inUseData: "مستخدمة بالفعل ولا يمكن حذفها",
        inUseActivated: " مستخدمة بالفعل ولا يمكن عدم تفعيلها"
    },
    countries: {
        basicInfo: 'البيانات الأساسية',
        label: 'بلد',
        deletedSuccessfully: 'تم حذف البلاد بنجاح',
        editedSuccessfully: 'تم تعديل البلد بنجاح',
        addedSuccessfully: 'تم إضافة بلد جديد بنجاح',
        nullFlag: 'يرجى إختيار صورة',
        countryCurrency: "عملة البلد",
        currencyPlaceholder: "إختر عملة البلد",
        countryCodePlaceholder: "إدخل كود البلد, مثال: +20",
        countryCode: 'كود البلد'
    },
    governorates: {
        basicInfo: 'البيانات الأساسية',
        label: 'محافظة',
        deletedSuccessfully: 'تم حذف المحافظات بنجاح',
        editedSuccessfully: 'تم تعديل المحافظة بنجاح',
        addedSuccessfully: 'تم إضافة محافظة جديدة بنجاح',
    },
    sideBar: {
        Home: 'الرئيسية',
        Courses: 'المواد',
        Assignments: 'الواجبات',
        Exams: 'الإختبارات',
        Results: 'النتائج',
        Schedule: 'جدول الحصص',
        Calendar: 'الجدول',
        Timetable: 'جدول الحصص',
        OnlineSessions: 'المحاضرات التفاعلية'
    },
    schools: {
        SchoolName:'اسم المدرسة',
        Fax:'فاكس',
        AddSchool: 'إضافة مدرسة',
        AddressLine1:'العنوان',
        AddressLine2:'إستكمال العنوان',
        PaymentMethod:'وسيلة الدفع',
        Website:'موقع الكتروني',
        SchoolEmail:'موقع المدرسه الالكترونى',
        AllowedStudents:'الطلاب المسموح لهم',
        ContactPersonName:"اسم شخص الاتصال",
        pleaseEngerName:"الرجاء إدخال الاسم",
        Position:"مركز",
        enterPosition:"الرجاء إدخال مركزك",
        email:"البريد الالكترونى",
        enterEmail:"الرجاء إدخال البريد الالكترونى",
        Country:"البلد",
        State:'المحافظة',
        selectCountery:"اختر بلد",
        selectState:"اختر محافظة",
        addContactPersonData: 'إضافة جهة إتصال',
        invalidURL: 'يرجى التأكد من إدخال رابط صحيح',
        schoolData: 'بيانات المدرسة',
        contactPersonData: 'بيانات جهات الإتصال',
        contactWorkPhone: 'هاتف العمل',
        contactPersonalPhone: 'الهاتف الشخصي',
        addContactModalTitle: 'إضافة جهة إتصال'
    },
    payment:{
        PaymentTypes:"أنواع الدفع",
        PaymentHistory:"تاريخ الدفع",
        Chooseyourpaymentplan:"اختر خطة الدفع التي ستتبعها",
        SelectPaymenttype:"اختر طريقة الدفع",
        Features:"المميزات:",
        CurrentStudentsNumber:"عدد الطلاب الحاليين:",
        student:"طالب",
        Changesby:"التغييرات بواسطة:",
        EffectiveDate:"تاريخ التنفيذ",
        LastChanges:"أخر التعديلات",
        seemore:'المزيد',
        CurrentTotalCost:"التكلفة الإجمالية الحالية",
        perStu:"ج م لكل طالب",
        NumberofdaysuntileveryPayment:"عدد الأيام حتى كل دفعة.",
        InspecificDayeverymonths:"في يوم محدد كل شهر.",
        days:"أيام",
        Mon:"أشهر"

    },
    Stations:{
        Newstation:'محطة جديدة',
        ViewStation:'عرض المحطة',
        StationsName:'اسم المحطات',
        NofChargers:'عدد اجهزه الشحن',
        ChoutofService:'خارج الخدمة',
        Governorate:'محافظة',
        Location:'موقع',
        AddNewStation : 'أضف محطة جديدة',
        StationName:'اسم المحطة',
        StationNameTranslation:'ترجمة إسم المحطة',
        PleaseInputStationName :'برجاء ادخال اسم المحطة ',
        stationNamePlaceHolder:'اكتب اسم المحطة',
        stationNameTranslationPlaceHolder: 'اكتب ترجمة إسم المحطة',
        NumberOfChargers:'عدد الشواحن',
        PleaseInputNumberOfChargers:'برجاء ادخال عدد الشواحن',
        NumberOfChargersPlaceholder:'ادخل عدد الشواحن',
        NumberOfOutOfServiceChargers:'عدد الشواحن خارج الخدمة',
        PleaseinputNumberOfoutOfService:'برجاءادخال عدد الشواحن خارج الخدمة',
        outOfServiceChargersPlaceholder:'ادخل عدد الشواحن خارج الخدمة',
        Governorate:'المحافظة',
        Chargers:'شواحن',
        Available:'متاح',
        Address:'العنوان',
        Directions:'الإتجاهات',
        DirectionsToStation:'الإتجاهات إلى المحطة',
        PleaseInputGovernorate:'برجاء ادخال المحافظة',
        stationGovernorate:'محافظة المحطة',
        LocationOnMap:'الموقع علي الخريطة',
        PleaseInputLocationonMap:'برجاء تحديد الموقع علي الخريطة',
        LocationPlaceholder:'حدد الموقع علي الخريطة',
        chargerIdPlaceholder: 'إدخال تعريف الشاحن',
        commentPlaceholder: 'إدخال تعليق على الشاحن',
        addCharger: '+ إضافة شاحن',
        chargerID: 'رقم الشاحن',
        chargerStatus: 'حالة الشاحن',
        chargerComment: 'تعليق',
        stationAddedSuccessfully: 'تم إضافة محطة شحن جديدة بنجاح',
        stationEditSuccessfully: 'تم تعديل محطة الشحن بنجاح',
        chargersNo: 'عدد الشواحن',
        Tiles: 'المحطات',
        all: 'الكل',
        StationsNearYou:'محطات شحن بالقرب منك',
        Findtheneareststation:'هنا تجد محطات الشحن القريبة من موقعك الحالي',
        Map: 'الخريطة',
        MoreInfo: 'معلومات أكثر',
        outOfServiceChargersNo: 'الشواحن خارج الخدمة',
        governateName: 'إسم المحافظة',
        alreadyExists: 'الشاحن موجود بالفعل',
    },Customers :{
        CustomersName : 'اسم العميل',
        Email : 'البريد الإلكتروني',
        Phone : 'الهاتف',
        CardStatus : 'حالة البطاقة',
        Lastcharge : 'اخر شحن',
        ViewProfile:'عرض الصفحة الشخصية',
        ViewCard:'عرض البطاقه',
        MobilePhone:'رقم التليفون',
        Age:'العمر',
        Gender:'الجنس',
        CarType:'نوع السيارة',
        BatteryRange:'سعة البطارية',
        carlicense:'رخصة القيادة',
        PersonalInfo:'معلومات شخصية',
        CarInfo:'معلومات السيارة',
        Comments:'تعليقات',
        writeyourcommenthere:'اكتب تعليقك هنا',
        new: 'جديد',
        active:'نشيط',
        inactive:'غير نشط',
        inProgress:'جاري العمل عليه',
        delivered:'تم التوصيل',
        Requested:'طلب جديد',
        needsAction: 'يحتاج لإجراء',
        inDelivery: 'جاري التوصيل',
        canceled: 'ملغى',
        RequestInfo:'معلومات الطلب',
        address:'العنوان',
        deliveryAddress:'عنوان التوصيل',
        male:'ذكر',
        fmale:'انثي',
        customerBan:'حظر العميل',
        commentSent:'تم ارسال التعليق بنجاح',
        statusUpdated:'تم تغيير الحاله بنجاح',
        userBaned:'تم حظر العميل بنجاح',
        userActivated: 'تم تفعيل العميل بنجاح'
    },
    PricingSettings : {
        Settings : 'الإعدادات',
        MinimumCharge : 'الحد الأدنى للشحن',
        KW : 'كيلووات',
        CurrentAC : 'التيار المتردد (AC)',
        CurrentDC : 'التيار المباشر (DC)',
        ChargersPricingUpdated : 'تم تحديث أسعار الشحن بنجاح',
    },
    infinty :{
        EVChargingpoints : 'نقاط شحن EV',
        foryourbusiness : 'لعملك',
        Morethan17Stations : 'أكثر من 17 محطة شحن EV فى القاهرة الكبرى.',
        Locateyourneareststation : 'حدد أقرب محطة لك',
        EnvironmentFriendlyDrivingPleasure : 'صديقة للبيئة ، متعة القيادة',
        zero : 'صفر',
        emissionisourvision : ' القضاء علي الانبعاثات هي رؤيتنا',
        Getyour: 'احصل على',
        ChargingCard: 'بطاقة الشحن',
        ChargingCardArticle: 'لتكون قادرًا على الشحن في أي محطة شحن من Infinity-e ، اطلب بطاقة الشحن الخاصة بك الآن واستمتع برسوم مجانية لفترة محدودة فقط.',
        GetYourCard: 'احصل على بطاقتك',
        Stations: 'المحطات',
        EVchargingstationstodate: 'محطات شحن EV حتى الآن',
        charginglocations: 'مواقع الشحن',
        charginglocationsby2021: 'مواقع الشحن بحلول عام 2021',
        Neareststation: 'أقرب محطة',
        Setlocation: 'تحديد المكان',
        typeyourlocation: 'اكتب موقعك هنا ...',
        LocatetheNearestStation: 'حدد موقع أقرب محطة شحن Infinity-e',
        Findtheneareststation: 'ابحث عن أقرب محطة',
        headerFooter : {
            Home : 'الصفحة الرئيسية',
            AboutUs : 'معلومات عنا',
            Howitworks : 'كيف تعمل',
            Stations : 'المحطات',
            ChargingStations : 'محطات الشحن',
            RequestCard : 'طلب بطاقة',
            ContactUs : 'اتصل بنا',
            About  : 'معلومات ',
            Additionallinks  : 'روابط إضافية ',
            infintyAdress : '57 كورنيش المعادي ، محافظة الجيزة ، القاهرة ، محافظة القاهرة 11431',
            SignIn  : 'تسجيل الدخول ',
            viewProfile:'الصفحة الشخصية',
            Notifications:'إشعارات',
            AllRightsReserved:'كل الحقوق محفوظة',
        }
    },
    contactusRequests: {
        requestStatus: 'حالة الطلب',
        status: 'حالة الطلب',
        requestDetails: 'تفاصيل الطلب',
        new: 'جديد',
        closed: 'مغلق',
        close: 'غلق',
        needsAction: 'يحتاج لإجراء',
        reply: 'الرد',
        replyHint: 'هذا الرد سيذهب مباشرة للبريد الإلكتروني الخاص بالعميل',
        commentHint: 'هذا التعليق سيظهر للمديرين فقط',
        replyPlaceholder: 'اكتب ردك على الطلب هنا',
        requestUpdatedSuccessfully: 'تم تعديل حالة الطلب بنجاح',
    },
    userProfile: {
        ChangePassword:'تغيير كلمه السر',
        NameMin:'الاسم لا يقل عن حرفين',
        NameMax: 'الأسم يجب ألا يزيد عن 12 حرف',
        Lastname20Max : 'لا يمكن أن يتجاوز اسم العائلة 20 حرفًا',
        Firstname20Max : 'لا يمكن أن يتجاوز الاسم الأول 20 حرفًا',
        emailMin: 'البريد الإلكتروني يجب أن يكون 5 حروف أو أكثر',
        emailMax: 'البريد الإلكتروني يجب ألا يزيد عن 150 حرف',
        Name:'الاسم',
        firstName: 'الإسم الأول',
        lastName: 'إسم العائلة',
        pleaseEnterValidmail:'الرجاء ادخال بريد الكتروني صالح',
        pleaseEntermail:'الرجاء ادخال البريد الالكتروني',
        mail:'البريد الالكتروني',
        Pleaseinputyourphonenumber:'رقم الهاتف يجب ان يكون 11 رقم',
        pleaseEnteraddress:'الرجاء ادخال العنوان',
        pleaseSelectGender:'يرجى تحديد الجنس',
        Male:"ذكر",
        Female:'انثي',
        minmaxaddress: 'العنوان يجب أن يكون من 3 إلي 300 حرف',
        ChangePassword:'تغيير الرقم السري',
        PersonalInfo:'معلومات شخصية',
        ChangeYourPassword:'تغيير رقمك السري',
        Uploadnewpicture:'رفع صوره جديده',
        address:'العنوان',
        Currentpassword:'الرقم السري الحالي',
        Newpassword:'الرقم السري الجديد',
        Confirmnewpassword:'تاكيد الرقم السري',
        CarInfo:'معلومات السيارة',
        CarsInfo:'معلومات السيارات الكهربائية',
        ArrivalAddress:'عنوان الوصول',
        sameashomeaddress:'نفس عنوان المنزل',
        oneofourcustomers:'إذا كنت أحد عملائنا ، فيرجى تسجيل الدخول أولاً',
        AddmoreCar:'اضافه المزيد من السيارات',
        Selectcarbrand:'اختار ماركة السيارة',
        Selectcartype: 'إختار نوع السيارة',
        pleaseEntercarbrand:'الرجاء ادخال ماركه السيارة',
        cartype:'نوع السيارة',
        pleaseEntercartype:'الرجاء ادخال نوع السيارة',
        pleaseEnterbetteryrange:'الرجاء ادخال سعة البطارية',
        Selectabetteryrange:'اختار سعة البطارية', 
        selectUserCar: 'يرجى إختيار سيارة',
        Attachcarlicense:'ارفق رخصة السيارة',
        copyofthecarlicense:'نسخة من رخصة السيارة',
        oryoucanattachcustomsreleasecertificate:'أو يمكنك إرفاق شهادة الإفراج الجمركي',
        Requests:'طلبات',
        carInfoAdded:'تم إضافة معلومات السيارة بنجاح',
        carlicenseNeed:'يرجى إضافة رخصة السيارة',
        NoRequests:'لا يوجد طلبات',
        youwanttocancel:'هل انت متاكد انك تريد الغاء الطلب',
        fillTheForm : 'يرجى ملء النموذج أدناه لطلب بطاقة شحن EV:',
        carsUpdated: 'تم تحديث بيانات السيارات بنجاح',
        deleteCar: 'حذف سيارة',
        carDeleteConfirmation: 'هل أنت متأكد من حذف هذه السيارة ؟',
        Createddate:'تاريخ الإنشاء' ,
        Status:'الحالة',
        DeliveryAddress:'عنوان التسليم',
        Action:'اجراء',
        Welcome :'مرحبا',
        foraneasierexperience:'للحصول على تجربة أسهل ، اجعل حسابك أفضل من خلال استكمال البيانات التالية.',
        Continue:'استمر',
        Skipfornow:'تخطي الآن',
        pleaseEntername:'برجاء ادخال الاسم',
        noLicenceSelected: 'يرجى إضافة صور الرخصة',
        licenseFront: 'يرجى إضافة صورة الرخصة الأمامية',
        licenseBack: 'يرجى إضافة صورة الرخصة الخلفية',
        Wallet:'المحفظة',
    },
    contactUs:{
        fullName:'الاسم كامل',
        email:'البريد الالكتروني',
        RequestDetails:'تفاصيل الطلب',
        PleaseinputyourRequestDetails:'الرجاء ادخال تفاصيل الطلب',
        HereToHelp:'نحن هنا للمساعدة',
        ContactUS:'تواصل معنا',
        ContactDetails:'تفاصيل التواصل',
        OpeningHours:'ساعات العمل',
        Closed:'مغلق',
        About:'عن',
        Howitworks:'كيف تعمل',
        ChargingStations:'محطة الشحن',
        RequestCard:'طلب بطاقة',
        Additionallinks:'روابط اضافية',
        AboutUs:'عنا',
        subjectMin: 'الموضوع يجب أن يكون 20 حرف على الأقل',
        subjectMax: 'الموضوع يجب أن يكون 200 حرف بحد أقصى',
        nameMin: 'الأسم يجب أن لا يقل عن حرفين',
        nameMax: 'الأسم يجب أن لا يتجاوز 60 حرف',
        dayopen : "الأحد  -الخميس",
        dayclose : "الجمعة-السبت",
    },
    notifications: {
        loadMore: 'عرض المزيد من التنبيهات',
        notifications: 'التنبيهات',
        noNotifications: "ليس لديك تنبيهات",
        today: 'اليوم'
    },
    aboutUs: {
        aboutUsPragraph:'تأسست Infinity-e  في عام 2018 وهي شركة تابعة لشركة Infinity إنرجي ، التي تقود قطاع الطاقة المتجددة في مصر. توفر Infinity-e حلول شحن للسيارات الكهربائية ، وتهدف إلى إنشاء شبكة شحن EV موثوقة ومريحة في مصر. تتوفر نقاط شحن EV الخاصة بنا في محطات Chillout على الطرق السريعة والطرق الرئيسية ، بالإضافة إلى مناطق حركة المرور العالية ، مثل مراكز التسوق ومباني المكاتب ، وما إلى ذلك ، بالإضافة إلى مجتمعات سكنية مسورة مختارة ، مثل مشاريع تطوير بالم هيلز ، إلخ.',
        Exploremore:'استكشاف المزيد',
        Ourstations:'محطاتنا',
        OurstationsPragraph1:'محطاتنا هي دليل مستقبلي مع جميع شواحن التيار المتردد التي نقدمها بقوة 22 كيلو واط في كل مقبس ، تتراوح شواحن التيار المستمر من 50 كيلو واط مع توفيرات تصل إلى 175 كيلو واط في المستقبل القريب للتأكد من أن لديك وقت للشحن السريع أثناء السير على الطريق. توفر أجهزة الشحن السريع 80٪ من سعة الشحن في أقل من ساعة واحدة.',
        OurstationsPragraph2:'جميع المحطات هي معايير الاتحاد الأوروبي التي تلبي احتياجات النوع 2 لشواحن التيار المتردد (يمكن شحن السيارات من النوع الأول باستخدام النوع 2 لكابلات النوع الأول) ، CCS2 و CHAdeMO. نحن نشحن ما يصل إلى 4 سيارات في محطة واحدة وبعض المحطات تلبي 6 EVs في المرة الواحدة.',
        Our:'',
        Partners:'شركائنا',
    },
    howItWork: {
        How:'كيف',
        itworks:'تعمل',
        Start:'اولا',
        StartStep:'قم بتوصيل كابل الشاحن بالسيارة والمحطة.',
        Second:'ثانيا',
        SecondStep:'ابدأ جلسة الشحن عن طريق تمرير بطاقة الشحن.',
        Third:'ثالثا',
        ThirdStep:'يشير ضوء LED الأزرق إلى بدء جلسة الشحن.',
        End:'اخيرا',
        EndStep:'أوقف جلسة الشحن عن طريق تمرير بطاقة الشحن ثم إزالة الكابل.',
        RequestCard:'طلب بطاقة',
        Requestcharging:'طلب بطاقة شحن لك',
        Search:'بحث',
        Searchlocation:'ابحث عن موقع المحطات هنا ببساطة',
    },
    signIn: {
        WelcomeBack:'مرحبا بعودتك !',
        Tokeepconnected:'للبقاء على اتصال معنا يرجى تسجيل الدخول باستخدام معلوماتك الشخصية',
        SignIn:'تسجيل الدخول',
        SignUp:'إنشئ حساب جديد',
        HelloFriend:'مرحبا يا صديقي!',
        personaldetails:'أدخل تفاصيلك الشخصية وابدأ رحلتك معنا',
        SignintoInfinity:'سجّل الدخول إلى Infinity',
        Forgotpassword:' نسيت رقمك السري ؟',
        haveaccount:'ليس لديك حساب؟',

    },
    courses: {
        TodaysCourses: "دورات اليوم",
        more:'المزيد',
        Day:'يوم',
        Week:'أسبوع',
        Month:'شهر',
        All: 'الكل',
        ToDo:'لم ينتهي',
        Completed:'منتهي',
        Draft:'مسودة',
        NoCoursesAdded :'لم تتم إضافة دورات',
        allCourses: 'كل الدورات',
        coursesSubtitle: 'دورات لتكملها اليوم',
        attachments: 'المرفقات',
        downloadAll: 'تحميل الكل',
    },
    filtrationBar: {
      filter: 'فلترة',
      subject: 'المادة',
      date: 'التاريخ',
      courseName: 'إسم الدرس',
      subjectPlaceholder: 'اختر المادة',
      datePlaceholder: 'اختر التاريخ',
      courseNamePlaceholder: 'اكتب إسم الدرس',
      allYear: 'العام كله',
      clear: 'ازالة',
      ResultType:'نوع النتيجة',
      ResultEvaluation:'تقييم النتيجة'
    },
    assigments : {
        Assigments : 'الواجبات',
        Assignment : 'الواجب',
        NoAssigmentsAdded : 'لم تتم إضافة مهام',
        NoFeedbackyet : "لا توجد تعليقات بعد ، أكمل مهمتك أولاً وانتظر ملاحظات معلمك",
        SuccessfullyPass : 'اجتاز بنجاح',
        Youhave : 'لديك ',
        assigmentstocompletetoday : 'واجبات لإكمال اليوم ',
        All : 'الكل',
        ToDo : 'المهام',
        Late : 'متأخر',
        Feedback : 'تقييم',
        Completed : 'اكتمل',
        Uncomplet : 'غير مكتملة',
        open : 'افتح',
        Feedback : 'تقييم',
        ReSubmit : 'إعادة إرسال',
        Teacherfeedback : "ملاحظات المدرس",
        TodayAssigments : "مهام اليوم",
        Applyyourassignmenthere : 'ارسل واجبك هنا..',
        AssignmentReply : 'تعيين رد',
        doesntmatchtherequirement : "لا يطابق المطلوب.",
        endDate: 'وقت النهاية',
        question: 'سؤال',
        degree: 'درجة',
        of : 'من',
        inProgress:'يتم تصحيحة' 
      },
      exams:{
        exams:'الإختبارات',
        headerSubtitle:'اختبارات لإكمالها اليوم',
        all : 'الكل',
        toDo : 'الإختبارات',
        upComing: 'الإختبارات القادمة',
        previous: 'الإختبارات السابقة',
        online: 'أونلاين',
        printed: 'مطبوع',
        hours: 'ساعات',
        hour: 'ساعة',
        questions: 'سؤال',
        startExam: 'إبدء الإختبار',
        instructions: 'التوجيهات',
        daysLeft: 'أيام متبقية',
        Beforeyoubegin: 'قبل ان تبدأ',
        Themaximumtime: 'الحد الأقصى للوقت الذي يسمح له بالوصول إلى الاختبار بعد وقت البدء.',
        Theresnooption: "ليس هناك خيار للتوقف. تأكد من أنك لن تنقطع ل",
        Ifyouaccidentallyclose: 'إذا أغلقت المتصفح عن طريق الخطأ ، فاستخدم رابط الدعوة للعودة إلى الاختبار.',
        Hintyoucanuse : 'تلميح: يمكنك استخدام IDE الخاص بك واستخدام لصق النسخ ، ولكن تأكد من تجميع الحل الخاص بك في بيئة اختبار Scholigit.',
        HowtouseitInstruction: 'كيفية استخدامه: تعليمات ونصائح',
        minutes: 'دقيقة',
        Hours: 'ساعات',
        StartTime: 'وقت البدء',
        EndTime: 'وقت النهاية',
        ExamDuration: 'مدة الامتحان',
        NQuestions: 'عدد الأسئلة',
        Questions: 'الأسئلة',
        Accessibilitytime: 'وقت الوصول',
        FromstartingTimeTill: 'من البداية حتى الوقت',
        checkTipNumberbelow: 'تحقق من نصيحة رقم 1 أدناه',
        noExamsFound: 'لا يوجد إمتحانات في الوقت الحالي',
        underCorrection: 'جاري التصحيح',
        under: 'جاري ',
        correction: 'التصحيح',
        totalDegree:'درجة' ,
        final:'إختبار نهائي',
        midterm:'إختبار نصف الفصل',
        monthly:'إختبار شهري',
        examTitle:'عنوان الامتحان',
        examType:'نوع الامتحان',
        subject:'المادة',
        start:'البدء',
        end:'النهاية'
      },
      Results:{
        Results:'النتائج',
        withinthetotal:'ضمن المجموع',
        Outoftotal:'خارج المجموع',
        Excellent:'ممتاز',
        VGood:'جيد جدا',
        Good:'جيد',
        Congratulations:'تهانينا',
        successfullypassed:'، لقد نجحت يتم الانتقال إلى الصف التالي.',
        LatestResult:'آخر النتائج',
        Archive:'أرشيف',
        NoResultsfoundyet:'لم يتم العثور على نتائج بعد',
        Yourresultswillappearhere:'ستظهر نتائجك هنا بمجرد انتهاء التصحيح',
        MoreDetails:'المزيد من التفاصيل'
      },
      exam: {
        Exam: 'امتحان',
        Class: 'فصل',
        startExam: 'بدء الامتحان',
        continueExam: 'إستكمال الامتحان',
        finishExam: 'الانتهاء من الامتحان',
        next: 'التالى',
        previous: 'السابق',
        FinishExam: 'إنهاء الإمتحان',
        solved: 'تم حلها',
        NotSolved: 'غير محلول',
        MarkForReview: 'علامة للمراجعة',
        minutes: 'دقيقة',
        Hours: 'ساعات',
        StartTime: 'وقت البدء',
        EndTime: 'وقت النهاية',
        ExamDuration: 'مدة الامتحان',
        NQuestions: 'عدد الأسئلة',
        Questions: 'الأسئلة',
        Accessibilitytime: 'وقت الوصول',
        FromstartingTimeTill: 'من البداية حتى الوقت',
        checkTipNumberbelow: 'تحقق من نصيحة رقم 1 أدناه',
        Beforeyoubegin: 'قبل ان تبدأ',
        Themaximumtime: 'الحد الأقصى للوقت الذي يسمح له بالوصول إلى الاختبار بعد وقت البدء.',
        Theresnooption: "ليس هناك خيار للتوقف. تأكد من أنك لن تنقطع ل",
        Ifyouaccidentallyclose: 'إذا أغلقت المتصفح عن طريق الخطأ ، فاستخدم رابط الدعوة للعودة إلى الاختبار.',
        Hintyoucanuse : 'تلميح: يمكنك استخدام IDE الخاص بك واستخدام لصق النسخ ، ولكن تأكد من تجميع الحل الخاص بك في بيئة اختبار Scholigit.',
        HowtouseitInstruction: 'كيفية استخدامه: تعليمات ونصائح',
        Areyouready: 'هل أنت جاهز ؟',
        Theexamisnotvalidanymore : 'الامتحان غير صالح بعد الآن',
        ThisexamURLisnotvalidanymore : 'لم يعد عنوان URL هذا للاختبار صالحًا ، فهو للاستخدام مرة واحدة فقط',
        GoToExamsPage : 'الانتقال إلى الصفحة الامتحانات',
        question: 'السؤال',
        point: 'النقطه',
        MultiChoise : 'إختر من متعدد',
        SingleChoise : 'إختر إجابة واحدة',
        Written : 'الفقرة',
        Trueorfalse : 'صحيح أم خطأ',
        true : 'صح',
        false : 'خطأ',
        connect: 'وصل',
        rearrange: 'أعد الترتيب',
        complete: 'أكمل',
        examSubmitted: 'تم إرسال الإمتحان بنجاح',
        wellDone: 'أحسنت',
        youDidGreat: 'لقد قمت بعمل عظيم',
        yourResult: 'نتيجك',
        done: 'إنهاء',
        waitForCorrection: 'يرجى الإنتظار لحين الإنتهاء من تصحيح الإمتحان الخاص بك, سوف يتم تنبيهك حين الإنتهاء من التصحيح',
        keepInMind: 'ضع في اعتبارك أنه عند النقر فوق "إنهاء" ، هذا يعني أنك قد أنهيت الاختبار ولا يمكنك الرجوع إلى إجاباتك مرة أخرى.',
        TextAreaTypeAnswer:'اكتب اجابتك هنا',
        showAnswer: 'عرض الإجابة',
        selectAnswerFirst:'الرجاء الإجابة على السؤال أولاً',
        questionQ:'س'
    },
    timeline: {
        addPhoto: 'أضف صورة',
        addVideo: 'أضف فيديو',
        Photo: 'صورة',
        Video: 'فيديو',
        File: 'ملف',
        post: 'إرسال',
        TimeLine: 'الجدول الزمني',
        Calender: 'التقويم',
        Attendens: 'الحضور',
        TimeTable: 'جدول المواعيد',
        HomeWork: 'الواجبات المنزلية',
        Exam: 'الامتحان',
        Cancel: 'إلغاء',
        createTagline: 'ماذا يحدث في فصلك؟',
        captionRequired: 'نص المنشور مطلوب',
        postCreatedSuccessfully: 'تم إضافة المنشور بنجاح',
        cropImage: 'تعديل الصورة المختارة',
        crop: 'تعديل',
        imageSize: 'حجم الصورة يجب أن يكون أقل من 10 ميجابايت',
        videoSize: 'حجم الفيديو يجب أن يكون أقل من 200 ميجابايت',
        invalidFormat: 'يرجى إختيار صيغة صورة أو فيديو صحيحة',
        invalidWidth: 'عرض الصورة يجب أن يكون أكبر من 400 بكسل',
        invalidHeight: 'طول الصورة يجب أن يكون أكبر من 400 بكسل',
        limitExceeded: "لا يمكن إختيار أكثر من 20 ملف",
        invalidImages: 'ملفات غير صالحة',
        DropToUpload: 'قم بإسقاط الملفات هنا للتحميل',
        Upload: 'نحميل',
    },
    calendar: {
        day: 'يوم',
        week: 'أسبوع',
        month: 'شهر',
        calendar: 'التقويم',
        youHave: 'لديك',
        remainingTasks: 'مهام متبقية',
        HomeWork:'واجب منزلي',
        OnlineSession:'حصة اونلاين',
        TimeTable:'الجدول الزمني',
        Solve:'حل',
        Join:'انضم',
        Ended:'انتهت'
    },
    todoList:{
        todoList:' قائمة المهام',
        TimeTable:'الجدول الزمني',
        HomeWork:'واجب منزلي',
        ClassOnline:'المحاضرات المباشرة',
        Time:'الوقت',
        Title:'العنوان',
        Topic:'الموضوع',
        Subject:'المادة',
        Teacher:'الأستاذ',
        Status:'الحالة',
        StartTime:' البدء',
        DueTime:' التسليم',
        Done:'تم',
        InProgress:'في تقدم',
        Join:'انضم',
        TimeRemining:'يبدأ بعد',
        Solve:'حل الواجب',
        empty:'المعذرة ، لا توجد مهام لهذا اليوم',
        YouHave:'لديك',
        Coursestocompletetoday:'دورات لإكمالها اليوم',
        to:'الي',
        Details:'تفاصيل',
        Completed:'منجز',
        Type:'النوع',
        OnlineSession:'جلسة اونلاين'

    },
    timetable : {
            Satarday : ' السبت',
            Sunday : 'الأحد',
            Monday : 'الاثنين',
            Teusday :'الثلاثاء',
            Wednesday : 'الأربعاء',
            Thursday : 'الخميس',
            Friday: 'الجمعه',
            Session :'الحصة',
            Sessions :'الحصص',
    },
    onlineSessions :{
        OnlineSessions:'المحاضرات التفاعلية',
        Courses:'الدورات',
        General:'العام',
        OnlineSessionTitle:'عنوان المحاضرة',
        Teacher:'المدرس',
        Subject:'الموضوع',
        Chapter:'الفصل',
        Topic:'الموضوع',
        Date :'التاريخ ',
        Details:'التفاصيل',
        Status:'الحالة',
        EmptyText:'عفوًا, لا يوجد محاضرات تفاعلية',
        Started:'بدأت',
        Scheduled:'مجدولة',
        Ended:'انتهت',
        Join:'انضم',
        TimeRemaining:'الوقت المتبقي' ,
        Done:'تم',
        Cancel:'الغاء',
        onlineSession: 'محاضرة تفاعلية',
        notStarted: 'لم تبدأ المحاضرة بعد',
        processing: 'جاري معالجة المحتويات...',
        emptyFiles: 'لا يوجد ملفات لهذه المحاضرة',
        general: 'عام',
        All:'الكل' 
        
    }
}